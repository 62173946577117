import { log } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { action, computed, makeObservable, observable } from 'mobx';
import { Task } from 'taskarian';
import { ConferenceRoomResource } from '../Conference/Types';
import { handleHttpError } from '../ErrorHandling';
import { broadcastHand, handleError, lowerHand, lowerOtherUserHand, raiseHand } from './effects';
import {
  HandActionError,
  HandEventClock,
  HandRaiseState,
  HandRaiseStatus,
  handDown,
  handUp,
  loweringHand,
  otherLoweringHand,
  raisingHand,
  whenUpdatable,
} from './types';

const handleHandActionError = (error: HandActionError): string => {
  switch (error.kind) {
    case 'bad-payload':
    case 'bad-status':
    case 'bad-url':
    case 'network-error':
    case 'timeout':
      return handleHttpError(error);
    case 'missing-link-error':
      return 'The link for broadcasting hand raise info is missing';
    case 'missing-application-id':
      return "Cannot broadcast hand raise info because this isn't a valid application";
    case 'missing-api-compatibility':
      return 'Cannot broadcast hand raise info because API compatibility is missing';
    case 'missing-conference-id-error':
      return 'Cannot broadcast hand raise info because the conference ID is missing';
  }
};

class HandRaiser {
  @observable
  readonly id: number;

  @observable
  state: HandRaiseState;

  private readonly resource: ConferenceRoomResource;

  constructor(id: number, resource: ConferenceRoomResource, status?: HandRaiseStatus) {
    makeObservable(this);

    this.id = id;
    this.resource = resource;
    this.state = {
      kind: status || 'hand-down',
      clock: { kind: 'hand-event-bottom' },
    };
  }

  @computed
  get isHandRaised(): boolean {
    switch (this.state.kind) {
      case 'hand-up':
        return true;
      case 'hand-down':
      case 'lowering-hand':
      case 'raising-hand':
      case 'other-lowering-hand':
        return false;
    }
  }

  @computed
  get statusBroadcaster(): Task<string, string> {
    switch (this.state.kind) {
      case 'hand-up':
      case 'hand-down':
        return broadcastHand(this.resource, this.state.clock, this.state.kind).mapError(
          handleHandActionError,
        );
      case 'lowering-hand':
      case 'raising-hand':
      case 'other-lowering-hand':
        return Task.fail<string, string>(`'${this.state.kind}' is not intended to be broadcasted`);
    }
  }

  @action
  setHandState(state: HandRaiseState) {
    log('setHandState', state);
    whenUpdatable(this.state.clock, state.clock).do(() => {
      this.state = state;
    });
  }

  @action
  handDown = (version: HandEventClock) => {
    this.setHandState(handDown(version));
  };

  @action
  handUp = (version: HandEventClock) => {
    this.setHandState(handUp(version));
  };

  @action
  raisingHand = () => {
    switch (this.state.kind) {
      case 'hand-down':
        const oldState = this.state;
        this.setHandState(raisingHand());
        raiseHand(this.resource).fork((err) => {
          handleError(err);
          this.setHandState(oldState);
        }, noop);
        break;
      case 'hand-up':
      case 'lowering-hand':
      case 'raising-hand':
      case 'other-lowering-hand':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loweringHand = () => {
    switch (this.state.kind) {
      case 'hand-up':
        const oldState = this.state;
        this.setHandState(loweringHand());
        lowerHand(this.resource).fork((err) => {
          handleError(err);
          this.setHandState(oldState);
        }, noop);
        break;
      case 'hand-down':
      case 'lowering-hand':
      case 'raising-hand':
      case 'other-lowering-hand':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  lowerOtherUserHand = () => {
    switch (this.state.kind) {
      case 'hand-up':
        const oldState = this.state;
        this.setHandState(otherLoweringHand());
        lowerOtherUserHand(this.resource, this.id).fork((err) => {
          handleError(err);
          this.setHandState(oldState);
        }, noop);
        break;
      case 'hand-down':
      case 'lowering-hand':
      case 'raising-hand':
      case 'other-lowering-hand':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default HandRaiser;
