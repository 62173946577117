import { readVarT } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Task } from 'taskarian';
import { loadGoogleTagManager } from './Loader';
import { GtmInitializationError } from './Types';

const handleGtmLoadError = (err: GtmInitializationError): void => {
  switch (err.kind) {
    case 'missing-var-error':
      warn(`Unable to load Google Tag Manager: Missing environment variable ${err.key}`);
      break;
    case 'gtm-load-error':
      warn('Google Tag Manager load error', err.key, err);
      break;
    default:
      assertNever(err);
  }
};

function GoogleTagManagerTooling() {
  useEffect(() => {
    Task.succeed<GtmInitializationError, {}>({})
      .assign('GTM_KEY', readVarT('VITE_GTM_KEY'))
      .assign('NEW_GTM_KEY', readVarT('VITE_NEW_GTM_KEY'))
      .andThen(({ GTM_KEY, NEW_GTM_KEY }) => loadGoogleTagManager(GTM_KEY, NEW_GTM_KEY))
      .fork(handleGtmLoadError, noop);
  });

  return <></>;
}

export default observer(GoogleTagManagerTooling);
