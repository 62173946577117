import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error, Error } from '../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { Link } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';
import { CourseDocumentResource } from '../../CourseDocument/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  resource: CourseDocumentResource;
}

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (): Loading => ({ kind: 'loading' });

export const ready = (resource: CourseDocumentResource): Ready => ({ kind: 'ready', resource });

export type State = Waiting | Loading | Ready | Error;

class LinkedCourseDocumentStore {
  @observable
  state: State = waiting();

  constructor(
    public documentId: string,
    public links: ReadonlyArray<Link>,
  ) {
    makeObservable(this);
  }

  @action
  load = () => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = loading();
        break;
      case 'ready':
        this.state = ready(this.state.resource);
        break;
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (resource: CourseDocumentResource) => {
    this.state = ready(resource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'waiting':
      case 'ready':
      case 'loading':
        return nothing();
    }
  }
}

export default LinkedCourseDocumentStore;
