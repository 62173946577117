import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { SelectOption } from '../../../components/Form/Select/Option';
import { error } from '../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { TPlainTextKey } from '../../../Translations';
import { EventPoll, EventPollResource } from '../../Types';
import {
  closed,
  open,
  pollClosed,
  pollLaunchActive,
  pollLaunched,
  pollSelected,
  pollSelectionReady,
  State,
} from './Types';

class PollsModalStore {
  @observable
  state: State = closed();

  constructor(public pollResources: EventPollResource[]) {
    makeObservable(this);
  }

  @action
  close = () => {
    this.state = closed();
  };

  @action
  open = () => {
    this.state = open();
  };

  @action
  selectPoll = (event?: React.ChangeEvent<HTMLSelectElement>) => {
    if (event) {
      this.state = pollSelected(event);
    } else {
      this.state = open();
    }
  };

  @action
  pollSelectionReady = (selectedPoll: EventPoll) => {
    this.state = pollSelectionReady(selectedPoll);
  };

  @action
  pollLaunchRequest = () => {
    switch (this.state.kind) {
      case 'closed':
      case 'poll-launch-request':
      case 'poll-selected':
      case 'open':
      case 'error':
      case 'poll-launch-active':
      case 'poll-closed':
        break;
      case 'poll-selection-ready':
        this.state = pollLaunched(this.state.selectedPoll);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  pollLaunchActive = () => {
    this.state = pollLaunchActive();
  };

  @action
  closePoll = () => {
    this.state = pollClosed();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get selectBoxOptions(): SelectOption[] {
    return this.pollResources.map((option) => ({
      content: option.payload.name,
      value: option.payload.name,
    }));
  }

  @computed
  get selectBoxValue(): string {
    switch (this.state.kind) {
      case 'closed':
      case 'poll-selected':
      case 'open':
      case 'error':
      case 'poll-launch-active':
      case 'poll-closed':
        return '';
      case 'poll-launch-request':
      case 'poll-selection-ready':
        return this.state.selectedPoll.name;
    }
  }

  @computed
  get previewUrl(): Maybe<string> {
    switch (this.state.kind) {
      case 'closed':
      case 'poll-selected':
      case 'open':
      case 'error':
      case 'poll-launch-active':
      case 'poll-closed':
        return nothing();
      case 'poll-launch-request':
      case 'poll-selection-ready':
        return just(this.state.selectedPoll.url);
    }
  }

  @computed
  get launchDisabled(): boolean {
    switch (this.state.kind) {
      case 'closed':
      case 'poll-selected':
      case 'open':
      case 'poll-launch-request':
      case 'error':
      case 'poll-launch-active':
      case 'poll-closed':
        return true;
      case 'poll-selection-ready':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'closed':
      case 'poll-selected':
      case 'open':
      case 'poll-launch-request':
      case 'poll-selection-ready':
      case 'poll-launch-active':
      case 'poll-closed':
        return nothing();
    }
  }
}

export default PollsModalStore;
