import { flatMap } from '@execonline-inc/collections';
import { action, computed, makeObservable, observable } from 'mobx';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import { PresenceMember } from '../../Socket/PresenceChannel/PresenceMembersStore/Types';

class GlobalPresenceMembersStore {
  @observable
  presenceStores: ReadonlyArray<PresenceMembersStore> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  addPresenceStore = (presenceStore: PresenceMembersStore) => {
    this.presenceStores = this.presenceStores.concat([presenceStore]);
  };

  @action
  removePresenceStore = (presenceStore: PresenceMembersStore) => {
    this.presenceStores = this.presenceStores.filter(
      (presenceMemberStore) => presenceMemberStore !== presenceStore,
    );
  };

  @computed
  get members(): ReadonlyArray<PresenceMember> {
    return flatMap((ps: PresenceMembersStore) => ps.members.slice(), this.presenceStores);
  }
}

export default GlobalPresenceMembersStore;
