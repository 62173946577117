import { action, computed, makeObservable, observable } from 'mobx';
import { State, closed, open } from './Types';

class ModuleToggleStore {
  @observable
  state: State = closed();

  constructor() {
    makeObservable(this);
  }

  @action
  toggle = () => {
    this.state = this.isOpen ? closed() : open();
  };

  @computed
  get isOpen(): boolean {
    return this.state.kind === 'open';
  }
}

export default ModuleToggleStore;
