import { Maybe } from 'maybeasy';
import { makeObservable, observable } from 'mobx';
import {
  ConferenceableResource,
  LocalConferenceRoomResource,
  whenLocalConferenceRoomResource,
} from '../../Conference/Types';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import { BreakoutStores, whenShouldRedirect } from './Types';

interface Redirecting {
  kind: 'redirecting';
  breakoutStore: BreakoutChannelStore;
}

interface NotPresent {
  kind: 'not-present';
}

interface Present {
  kind: 'present';
  conferenceRoomResource: LocalConferenceRoomResource;
  breakoutStores: Maybe<BreakoutStores>;
}

type State = Redirecting | NotPresent | Present;

const redirecting = (breakoutStore: BreakoutChannelStore): Redirecting => ({
  kind: 'redirecting',
  breakoutStore,
});

const notPresent = (): NotPresent => ({
  kind: 'not-present',
});

const present = (
  conferenceRoomResource: LocalConferenceRoomResource,
  breakoutStores: Maybe<BreakoutStores>,
): Present => ({
  kind: 'present',
  conferenceRoomResource,
  breakoutStores,
});

const state = (conferenceableResource: ConferenceableResource): State => {
  return conferenceableResource.payload.conferenceRoom
    .andThen(whenLocalConferenceRoomResource)
    .map((conferenceRoomResource) => {
      const breakoutStores = conferenceRoomResource.payload.breakoutRooms.map((breakouts) =>
        breakouts.map((breakout) => new BreakoutChannelStore(breakout)),
      );
      return whenShouldRedirect(breakoutStores).cata<State>({
        Ok: redirecting,
        Err: (stores) => present(conferenceRoomResource, stores),
      });
    })
    .getOrElse(notPresent);
};

class ConferenceStore {
  @observable
  state: State;

  constructor(conferenceableResource: ConferenceableResource) {
    makeObservable(this);

    this.state = state(conferenceableResource);
  }
}

export default ConferenceStore;
