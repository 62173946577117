import { observer } from 'mobx-react';
import SegmentStore from '../../../../SegmentStore';
import * as fieldStyle from '../../FormField/style.module.css';
import { StringFormField } from '../../Types';
import { formatInputValue, inputValue } from '../Helpers';
import { useFormField } from '../hooks';
import Label from '../Label';
import * as style from './style.module.css';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: StringFormField;
  uuid: string;
}

function StringField({ segmentStore, fieldDescription, uuid }: Props) {
  const { fieldValue, isRequired, addValue } = useFormField(segmentStore, fieldDescription, uuid);

  return (
    <div className={fieldStyle.field}>
      <Label label={fieldDescription.label} />
      <textarea
        name={fieldDescription.name}
        value={inputValue(fieldDescription, fieldValue)}
        required={isRequired}
        minLength={fieldDescription.minlength.getOrElseValue(0)}
        maxLength={fieldDescription.maxlength}
        onChange={(e) => addValue(formatInputValue(fieldDescription, e.currentTarget.value))}
        className={style.textArea}
      />
    </div>
  );
}

export default observer(StringField);
