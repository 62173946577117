import { action, makeObservable, observable } from 'mobx';
import { ExitContext } from '../Types';
import { State, exiting, ready } from './Types';

class ExitStore {
  @observable
  state: State = ready();

  constructor() {
    makeObservable(this);
  }

  @action
  exit = (exitContext: ExitContext) => {
    this.state = exiting(exitContext);
  };
}
export default ExitStore;
