import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { action, makeObservable, observable } from 'mobx';
import { TPlainTextKey } from '../../../../Translations';
import {
  loadingPreviewResource,
  loadingRegisterResource,
  previewResourceError,
  ready,
  readyLockedAccess,
  RegisterResource,
  registrationResourceError,
  State,
  waiting,
} from './Types';

class RegisterResourceStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  loading = (guid: string) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
        this.state = loadingRegisterResource(guid);
        break;
      case 'loading-register-resource':
      case 'loading-preview-resource':
      case 'preview-resource-error':
      case 'register-resource-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingPreview = (id: string) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
        this.state = loadingPreviewResource(id);
        break;
      case 'loading-register-resource':
      case 'loading-preview-resource':
      case 'preview-resource-error':
      case 'register-resource-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'loading-register-resource':
        this.state = registrationResourceError(message);
        break;
      case 'loading-preview-resource':
        this.state = previewResourceError(message);
        break;
      case 'waiting':
      case 'register-resource-error':
      case 'preview-resource-error':
      case 'ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (readyResource: RegisterResource) => {
    switch (this.state.kind) {
      case 'loading-register-resource':
      case 'loading-preview-resource':
        this.state = ready(readyResource);
        break;
      case 'waiting':
      case 'preview-resource-error':
      case 'register-resource-error':
      case 'ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyLockedAccess = (message: Maybe<TPlainTextKey>) => {
    switch (this.state.kind) {
      case 'ready':
        this.state = readyLockedAccess(this.state.resource, message);
        break;
      case 'loading-register-resource':
      case 'loading-preview-resource':
      case 'waiting':
      case 'preview-resource-error':
      case 'register-resource-error':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default RegisterResourceStore;
