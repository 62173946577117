import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { CalendarDay } from '../Calendaring';
import { error } from '../ErrorHandling';
import { EventResource, startsOn } from '../EventsStore/Types';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Linkable } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import {
  CalendarResource,
  CalendarState,
  loading,
  Navigable,
  navigating,
  ready,
  refresh,
  waiting,
} from './Types';

class CalendarStore {
  @observable
  state: CalendarState;

  constructor(linkable: Linkable) {
    makeObservable(this);

    this.state = waiting(linkable);
  }

  @action
  loading = (linkable: Linkable) => {
    this.state = loading(linkable);
  };

  @action
  navigating = (nav: Navigable) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'navigating':
      case 'error':
      case 'refresh':
        break;
      case 'ready':
        this.state = navigating(this.state.calendarResource, nav);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  refresh = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'navigating':
      case 'error':
      case 'refresh':
        break;
      case 'ready':
        this.state = refresh(this.state.calendarResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (calendarResource: CalendarResource) => {
    this.state = ready(calendarResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'refresh':
      case 'navigating':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get whenLinks(): Maybe<Linkable> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
        return just(this.state.linkable);
      case 'ready':
      case 'refresh':
      case 'error':
      case 'navigating':
        return nothing();
    }
  }

  events = (day: CalendarDay): Maybe<NonEmptyList<EventResource>> => {
    switch (this.state.kind) {
      case 'ready':
      case 'refresh':
      case 'navigating':
        const calendarResource = this.state.calendarResource.payload;
        const events = calendarResource.eventsResource.payload;
        return fromArrayMaybe(events.filter(startsOn(day.date)));
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  };
}

export default CalendarStore;
