import { first } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { asMaybe } from '@execonline-inc/error-handling';
import { whenBetweenEq, whenLt } from '@execonline-inc/numbers';
import { array } from 'jsonous';
import { Maybe, just } from 'maybeasy';
import { getFieldValue } from '../../../../FormFields';
import { formFieldValueDecoder } from '../../Decoders';
import {
  FieldDescription,
  FieldValue,
  FormFieldOutputValue,
  SelectFormField,
  StringFormField,
} from '../../Types';

export const whenMaxLengthValid = (
  fieldValue: FieldValue[],
  fieldDescription: SelectFormField | StringFormField,
): Maybe<number> => {
  switch (fieldDescription.kind) {
    case 'multiple-selection':
      return just(fieldValue.length).andThen(whenLt(fieldDescription.maxSelections));
    case 'string':
      return first(fieldValue)
        .map((fieldValue) => fieldValue.value.length)
        .andThen(whenLt(fieldDescription.maxlength));
  }
};

export const validateField = (value: FieldValue[], description: FieldDescription) => {
  switch (description.kind) {
    case 'multiple-selection':
      const { minSelections, maxSelections } = description;

      return just(value.length).andThen(whenBetweenEq(minSelections, maxSelections)).isJust();
    default:
      const { minlength, maxlength } = description;

      return first(value)
        .map((fieldValue) => fieldValue.value.length)
        .andThen(whenBetweenEq(minlength.getOrElseValue(0), maxlength))
        .isJust();
  }
};

const validateValue = (value: FormFieldOutputValue): Maybe<FieldValue[]> =>
  asMaybe(array(formFieldValueDecoder).decodeAny(value));

export const fieldValueM = (results: Maybe<JsonValue>, uuid: string) =>
  getFieldValue(results, uuid).andThen(validateValue).getOrElseValue([]);

export const fieldHasValue = (value: FieldValue, fieldValue: FieldValue[]): boolean =>
  fieldValue.some((v) => v.label === value.label);
