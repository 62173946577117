import { mapMaybe } from '@execonline-inc/collections';
import { readVarM } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { popSavedApplicationLocation } from '@execonline-inc/navigation.private';
import {
  Requesting,
  SessionData,
  sessionDataResponseDecoder,
} from '@execonline-inc/unified-auth.private';
import { assertNever, identity, noop } from '@kofno/piper';
import { BadStatus, header, toHttpTask } from 'ajaxian';
import { just } from 'maybeasy';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { currentUserStore } from '../CurrentUser/Store';
import history from '../History';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { resourceDecoder } from '../Resource/Decoders';
import { Link } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import { RootResourceError } from '../RootResourceStore/Types';
import { establishSession } from '../Session';
import { TPlainTextKey } from '../Translations';
import { SessionStore } from './Store';

interface Props extends RCProps<SessionStore> {}

export class SessionReactions extends ReactionComponent<
  SessionStore,
  SessionStore['state'],
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: SessionStore['state']): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'requesting':
        requestSessionData().fork(handleRequestError(store, state), establishSession);
        break;
      case 'present':
        popSavedApplicationLocation().fork(noop, (l) => history.replace(l));
        break;
      case 'absent':
        break;
      case 'errored':
        warn('Error occurred when preparing application session:', toJS(state.error));
        break;
      default:
        assertNever(state);
    }
  };
}

export const requestSessionData = (): Task<RootResourceError, SessionData> =>
  rootResourceStore
    .findLinkT('app-session')
    .andThen(sessionRequest)
    .map(({ payload }) => payload);

export const sessionResourceDecoder = resourceDecoder(sessionDataResponseDecoder);

export const sessionRequest = (link: Link) =>
  toHttpTask({
    url: link.href,
    decoder: sessionResourceDecoder.toJsonFn(),
    method: link.method,
    timeout: 0,
    data: undefined,
    withCredentials: false,
    headers: mapMaybe(identity, [
      readVarM('VITE_APPLICATION_ID').map((appID) => header('Application-ID', appID)),
    ]).slice(),
  });

const handleBadStatus = (
  error: BadStatus,
  store: SessionStore,
  state: Requesting<TPlainTextKey>,
): void => {
  switch (error.response.status) {
    case 401:
      currentUserStore.anonymous(state.messageOnFailure);
      store.absent();
      break;
    case 404:
      currentUserStore.anonymous(just('Your session has expired, please log in again.'));
      store.absent();
      break;
    default:
      store.errored(error);
  }
};

const handleRequestError =
  (store: SessionStore, state: Requesting<TPlainTextKey>) =>
  (error: RootResourceError): void => {
    switch (error.kind) {
      case 'missing-link-error':
      case 'missing-application-id':
      case 'missing-api-compatibility':
      case 'bad-payload':
      case 'bad-url':
      case 'network-error':
      case 'timeout':
        store.errored(error);
        break;
      case 'bad-status':
        handleBadStatus(error, store, state);
        break;
      default:
        assertNever(error);
    }
  };
