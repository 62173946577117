import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import AttachmentUploadStore from '../AttachmentUploadStore';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import SegmentStore from '../SegmentStore';
import { TeamDiscussionSubmissionParams } from '../SegmentStore/Types';
import { TPlainTextKey } from '../Translations';
import { Uploads } from '../Uploads';
import { loading, ready, TeamDiscussionSegment, TeamDiscussionState, waiting } from './Types';

class TeamDiscussionStore {
  @observable
  state: TeamDiscussionState;
  segmentStore: SegmentStore;
  attachmentUploadStore: AttachmentUploadStore;

  constructor(
    segmentStore: SegmentStore,
    attachmentUploadStore: AttachmentUploadStore,
    segment: TeamDiscussionSegment,
  ) {
    makeObservable(this);

    this.segmentStore = segmentStore;
    this.attachmentUploadStore = attachmentUploadStore;
    this.state = waiting(segment);
  }

  @computed
  get teamDiscussionTitle(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.submissionParams.title;
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamDiscussionContent(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.submissionParams.content;
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamDiscussionUploads(): Maybe<Uploads> {
    switch (this.state.kind) {
      case 'ready':
        return this.attachmentUploadStore.uploadsToSubmit;
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamId(): Maybe<number> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.segment.teamId;
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamDiscussionSubmissionParameters(): TeamDiscussionSubmissionParams {
    return {
      type: 'team-discussion',
      title: this.teamDiscussionTitle,
      content: this.teamDiscussionContent,
      uploads: this.teamDiscussionUploads,
      attachmentUploadStore: this.attachmentUploadStore,
    };
  }

  @computed
  get areSubmissionParamsValid(): boolean {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'error':
        return false;
      case 'ready': {
        const submissionParams = this.state.submissionParams;
        return submissionParams.title
          .andThen(() => submissionParams.content)
          .map(() => true)
          .getOrElseValue(false);
      }
    }
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'waiting': {
        this.state = loading(this.state.segment);
        break;
      }
      case 'loading':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'loading': {
        this.state = ready(this.state.segment);
        break;
      }
      case 'waiting':
      case 'ready':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  setTitle = (title: string) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.submissionParams.title = fromEmpty(title);
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setContent = (content: string) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.submissionParams.content = fromEmpty(content);
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      default:
        return nothing();
    }
  }
}

export default TeamDiscussionStore;
