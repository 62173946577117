import { type Error } from '../../../ErrorHandling';
import { EventPoll } from '../../Types';

interface Closed {
  kind: 'closed';
}

interface Open {
  kind: 'open';
}

interface PollSelected {
  kind: 'poll-selected';
  selectionEvent: React.FormEvent<HTMLSelectElement>;
}

interface PollSelectionReady {
  kind: 'poll-selection-ready';
  selectedPoll: EventPoll;
}

interface PollLaunchRequest {
  kind: 'poll-launch-request';
  selectedPoll: EventPoll;
}

interface PollLaunchActive {
  kind: 'poll-launch-active';
}

interface PollClosed {
  kind: 'poll-closed';
}

export const pollClosed = (): PollClosed => ({ kind: 'poll-closed' });

export const pollLaunchActive = (): PollLaunchActive => ({ kind: 'poll-launch-active' });

export const pollSelectionReady = (selectedPoll: EventPoll): PollSelectionReady => ({
  kind: 'poll-selection-ready',
  selectedPoll,
});

export const pollLaunched = (selectedPoll: EventPoll): PollLaunchRequest => ({
  kind: 'poll-launch-request',
  selectedPoll,
});

export const pollSelected = (selectionEvent: React.FormEvent<HTMLSelectElement>): PollSelected => ({
  kind: 'poll-selected',
  selectionEvent,
});

export const closed = (): Closed => ({ kind: 'closed' });

export const open = (): Open => ({ kind: 'open' });

export type State =
  | Closed
  | Open
  | PollSelected
  | PollSelectionReady
  | PollLaunchRequest
  | PollLaunchActive
  | PollClosed
  | Error;
