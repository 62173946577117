import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../../SegmentStore';
import { AnyAdvancer, ConfirmationModal } from '../../../../../SegmentStore/Types';
import { AdvanceButton } from '../../Common';
import AdvancerButton from '../../Common/AdvancerButton';
import CompleteAndAdvanceButton from '../CompleteAndAdvanceButton';
import ConfirmCompleteAndAdvanceButton from '../ConfirmCompleteAndAdvanceButton';
import SubmitAndAdvanceButton from '../SubmitAndAdvanceButton';

interface Props extends ButtonProps {
  advancer: AnyAdvancer;
  confirmationModal: Maybe<ConfirmationModal>;
  store: SegmentStore;
}

function ReadyButton({ advancer, confirmationModal, store, children, ...others }: Props) {
  switch (advancer.kind) {
    case 'advancer':
      return (
        <AdvancerButton {...others} onClick={advancer.advance} store={store}>
          {children}
        </AdvancerButton>
      );
    case 'complete-and-advancer':
      return confirmationModal
        .map((m) => (
          <ConfirmCompleteAndAdvanceButton
            {...others}
            confirmationModal={m}
            onConfirm={advancer.completeAndAdvance}
          >
            {children}
          </ConfirmCompleteAndAdvanceButton>
        ))
        .getOrElse(() => (
          <CompleteAndAdvanceButton {...others} onClick={advancer.completeAndAdvance}>
            {children}
          </CompleteAndAdvanceButton>
        ));
    case 'submit-and-advancer':
      return (
        <SubmitAndAdvanceButton store={store} {...others}>
          {children}
        </SubmitAndAdvanceButton>
      );
    case 'disabled-advancer':
      return (
        <AdvanceButton {...others} disabled>
          {children}
        </AdvanceButton>
      );
    case 'no-advancer':
      return emptyFragment();
  }
}

export default observer(ReadyButton);
