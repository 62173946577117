import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert, successAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import {
  CountryOption,
  CountryOptionsResource,
  CountryOptionsResourceWithErrors,
  CountryOptionsState,
  loading,
  ready,
  readyWithErrors,
  waiting,
} from './Types';

class CountryOptionsStore {
  @observable
  state: CountryOptionsState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'ready':
      case 'error':
      case 'ready-with-errors':
        this.state = loading();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (countryOptionsResource: CountryOptionsResource, flashMessage: Maybe<TPlainTextKey>) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
        this.state = ready(countryOptionsResource, flashMessage);
        break;
      case 'waiting':
      case 'error':
      case 'ready-with-errors':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithErrors = (countryOptionsResource: CountryOptionsResourceWithErrors) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready-with-errors':
        this.state = readyWithErrors(countryOptionsResource);
        break;
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
      case 'waiting':
      case 'error':
      case 'ready-with-errors':
        this.state = error(msg);
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
        return this.state.flashMessage.andThen(fromEmpty).map(successAlert);
      case 'ready-with-errors':
      case 'waiting':
      case 'loading':
        return nothing();
    }
  }

  @computed
  get countryOptions(): Maybe<NonEmptyList<CountryOption>> {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-errors':
        return fromArrayMaybe(this.state.countryOptionsResource.payload.countryOptions);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }
}

export default CountryOptionsStore;
