import { assertNever } from '@kofno/piper';
import { action, makeObservable, observable } from 'mobx';
import { hiding, showing, State } from './Types';

class Store {
  @observable
  state: State = showing();

  constructor() {
    makeObservable(this);
  }

  @action
  hiding = (): void => {
    switch (this.state.kind) {
      case 'showing':
        this.state = hiding();
        break;
      case 'hiding':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default Store;
