import { readVarM } from '@execonline-inc/environment';
import { useMessageBusHost } from '@execonline-inc/message-bus.private';

export function Host() {
  useMessageBusHost({
    adminstudio: readVarM('VITE_ADMINSTUDIO_APP_URL'),
    enterprise: readVarM('VITE_ENTERPRISE_APP_URL'),
    learning: readVarM('VITE_LEARNING_APP_URL'),
  });
  return <></>;
}
