import { assertNever } from '@kofno/piper';
import * as H from 'history';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { errorAlert, FlashAlert } from '../../Notifications/Types';
import { TPlainTextKey } from '../../Translations';
import { error, navigate, ready, State, waiting } from './Types';

export default class Store {
  @observable
  state: State;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  ready = (to: H.LocationDescriptor) => {
    this.state = ready(to);
  };

  @action
  navigate = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = navigate(this.state.to);
        break;
      case 'waiting':
      case 'navigate':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    this.state = error(message);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'navigate':
        return nothing();
      case 'error':
        return just(this.state).map(errorAlert);
    }
  }
}
