import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../../../components/Modal/Store';
import EnrollmentStore from '../Enrollment/Store';
import ExpiredAccessModalContentInfo from './ExpiredAccessModalContentInfo';

interface Props {
  modalStore: ModalStore;
  enrollmentStore: EnrollmentStore;
}

const ExpiredAccessModalContent: React.FC<Props> = ({ modalStore, enrollmentStore }) => {
  switch (enrollmentStore.state.kind) {
    case 'server-error':
      return (
        <ExpiredAccessModalContentInfo
          modalStore={modalStore}
          enrollmentStore={enrollmentStore}
          message={enrollmentStore.state.message.text}
        />
      );
    case 'payment-loading-error':
    case 'error':
    case 'waiting':
    case 'setting-up':
    case 'waiting-commerce':
    case 'initializing-payment':
    case 'ready-for-payment':
    case 'creating':
    case 'created':
    case 'exiting':
      return <></>;
  }
};

export default observer(ExpiredAccessModalContent);
