import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  ChatMessageReplies,
  ChatablePersonResourceList,
} from '../../../../ConversationStore/Types';
import {
  PreviousState,
  RepliesState,
  abridged,
  createEmptyReplies,
  createRepliesState,
} from './Types';

interface InternalState {
  replies: ChatMessageReplies;
  chatablePersonResourceList: ChatablePersonResourceList;
}

export default class MessageReplyStore {
  messageId: number;
  previousState: PreviousState;
  @observable
  internalState: InternalState;

  @observable
  abridged: boolean;

  constructor(
    id: number,
    replies: ChatMessageReplies,
    chatablePersonResourceList: ChatablePersonResourceList,
    previousState: Maybe<PreviousState>,
  ) {
    makeObservable(this);

    this.messageId = id;
    this.abridged = abridged(previousState);
    this.previousState = previousState.getOrElseValue('empty-replies');
    this.internalState = {
      replies,
      chatablePersonResourceList,
    };
  }

  @computed
  get repliesState(): RepliesState {
    const { replies, chatablePersonResourceList } = this.internalState;
    return replies
      .map<RepliesState>(createRepliesState(this.abridged, chatablePersonResourceList))
      .getOrElse(createEmptyReplies);
  }

  @action
  expand = (): void => {
    switch (this.repliesState.kind) {
      case 'abridged-replies':
        this.abridged = false;
        this.previousState = 'expanded-replies';
        break;
      case 'empty-replies':
      case 'expanded-replies':
      case 'lone-reply':
        break;
      default:
        assertNever(this.repliesState);
    }
  };

  @action
  abridge = (): void => {
    switch (this.repliesState.kind) {
      case 'expanded-replies':
        this.abridged = true;
        this.previousState = 'abridged-replies';
        break;
      case 'empty-replies':
      case 'lone-reply':
      case 'abridged-replies':
        break;
      default:
        assertNever(this.repliesState);
    }
  };

  @computed
  get previousRepliesState(): PreviousState {
    return this.previousState;
  }
}
