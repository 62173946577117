import { log, warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Task } from 'taskarian';
import { DOMError, appendChild, createElement, getElementById, removeChild } from '../../Document';
import { type Error } from '../../ErrorHandling';
import { fromNullableT } from '../../TaskExt';
import { T } from '../../Translations';
import { FlashMessage } from '../../components/FlashMessage';
import Select from '../../components/Form/Select';
import * as modalStyle from '../../components/Modal/style.module.css';
import { EventPollsResource } from '../Types';
import PollsModalStore from './PollsModalStore';
import * as style from './style.module.css';

interface Props {
  store: PollsModalStore;
  pollsResource: EventPollsResource;
}

class PollsModal extends React.Component<Props> {
  portal?: HTMLElement;

  constructor(props: Props) {
    super(props);
    Task.succeed<DOMError, {}>({})
      .assign('root', getElementById('root'))
      .assign('child', createElement('div'))
      .do(({ child }) => {
        child.id = 'pollsModalPortal';
      })
      .andThen(({ root, child }) => appendChild(root, child))
      .fork(
        ({ message }) => warn(message),
        (portal) => {
          this.portal = portal;
        },
      );
  }

  componentWillUnmount() {
    Task.succeed<Error | DOMError, {}>({})
      .assign('portal', fromNullableT(this.portal))
      .assign('root', getElementById('root'))
      .andThen(({ root, portal }) => removeChild(root, portal))
      .fork(
        ({ message }) => warn(message),
        () => log('Unmount succeed'),
      );
  }

  render() {
    const { store } = this.props;
    switch (store.state.kind) {
      case 'closed':
      case 'poll-launch-active':
      case 'poll-closed':
        return <></>;
      case 'error':
      case 'open':
      case 'poll-launch-request':
      case 'poll-selected':
      case 'poll-selection-ready':
        return (
          this.portal &&
          ReactDOM.createPortal(
            <div className={modalStyle.modal}>
              <div className={style.content}>
                {store.notification
                  .map((alert) => <FlashMessage alert={alert} />)
                  .getOrElseValue(<></>)}
                <div className={style.header}>
                  <h3>
                    <T kind="Choose which poll to launch" />
                  </h3>
                  <p>
                    <T kind="Launching a poll will activate ..." />
                  </p>
                </div>
                <Select
                  className={style.selectBox}
                  value={store.selectBoxValue}
                  placeholder="Select One..."
                  onChange={store.selectPoll}
                  name="selectedPoll"
                  options={store.selectBoxOptions}
                  id="selectedPoll"
                />

                {store.previewUrl
                  .map((url) => (
                    <>
                      <p className={style.pollPreviewTitle}>
                        <T kind="Poll Preview" />
                      </p>
                      <iframe className={style.pollIframe} src={url} />
                    </>
                  ))
                  .getOrElseValue(<></>)}
                <button
                  className={style.launchButton}
                  disabled={store.launchDisabled}
                  onClick={store.pollLaunchRequest}
                >
                  <T kind="Launch Poll" />
                </button>
              </div>
              <div
                className={modalStyle.shade}
                onClick={store.close}
                data-test-modal-shade={true}
              />
            </div>,
            this.portal,
          )
        );
    }
  }
}

export default observer(PollsModal);
