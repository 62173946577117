import { fromEmpty } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { ExperienceFilters } from '../../../../Common/Experience/Types';
import { CommonFilter, State, blank } from '../CommonFilter/Types';

abstract class CommonFilterStore {
  @observable
  state: State;

  protected readonly _filters: ExperienceFilters;

  constructor(filters: ExperienceFilters) {
    makeObservable(this);

    this._filters = filters;

    const initialFilterValues: CommonFilter = {
      selectedValues: [],
      appliedValues: [],
    };

    this.state = blank(initialFilterValues);
  }

  abstract getFilterValues(): string[];

  @action
  appendSelectedValues = (value: string[]) => {
    this.state.filter.selectedValues = value;
  };

  @action
  applySelectedValues = () => {
    this.state.filter.appliedValues = this.state.filter.selectedValues;
  };

  @action
  resetSelectedValues = () => {
    this.state.filter.selectedValues = this.state.filter.appliedValues;
  };

  @action
  clearSelectedValues = () => {
    this.appendSelectedValues([]);
    this.applySelectedValues();
  };

  @computed
  get selectedValues() {
    return this.state.filter.selectedValues;
  }

  @computed
  get appliedValues() {
    return fromEmpty(this.state.filter.appliedValues).getOrElse(() => this.getFilterValues());
  }

  @computed
  get appliedCount() {
    return this.state.filter.appliedValues.length;
  }
}

export default CommonFilterStore;
