import { Group, groupBy } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { Error, error } from '../ErrorHandling';
import NoteStore, { readyStore } from '../NoteStore';
import { NoteResource } from '../NoteStore/Types';
import { FlashAlert, errorAlert, successAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import { NotesResource } from './Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  notesResource: NotesResource;
  noteStores: NoteStore[];
  flashMessage: Maybe<TPlainTextKey>;
}

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const loading = (): Loading => ({
  kind: 'loading',
});

const ready = (notesResource: NotesResource, notesStore: NotesStore): Ready => ({
  kind: 'ready',
  notesResource,
  noteStores: notesResource.payload.map(readyStore(notesStore)),
  flashMessage: nothing(),
});

export type State = Waiting | Loading | Error | Ready;

class NotesStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = (notesResource: NotesResource) => {
    this.state = ready(notesResource, this);
  };

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  remove = (note: NoteResource) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.notesResource.payload = this.state.notesResource.payload.filter(
          (n) => n.payload.id !== note.payload.id,
        );
        this.state.noteStores = this.state.noteStores.filter((ns) => !ns.represents(note));
        this.state.flashMessage = just('Note deleted successfully');
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  add = (note: NoteResource) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.notesResource.payload.unshift(note);
        this.state.noteStores.unshift(readyStore(this)(note));
        this.state.flashMessage = just('Note created successfully');
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get noteResources(): NoteResource[] {
    switch (this.state.kind) {
      case 'ready':
        return this.state.notesResource.payload;
      case 'waiting':
      case 'loading':
      case 'error':
        return [];
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'ready': {
        return this.state.notesResource.links;
      }
      case 'waiting':
      case 'loading':
      case 'error':
        return [];
    }
  }

  @computed
  get noteStores(): NoteStore[] {
    switch (this.state.kind) {
      case 'ready': {
        return this.state.noteStores;
      }
      case 'waiting':
      case 'loading':
      case 'error':
        return [];
    }
  }

  @computed
  get noteStoresByProgram(): Group<NoteStore> {
    switch (this.state.kind) {
      case 'ready': {
        return groupBy<NoteStore>((ns) => ns.programTitle)(this.state.noteStores);
      }
      case 'waiting':
      case 'loading':
      case 'error':
        return {} as Group<NoteStore>;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
        return this.state.flashMessage.map(successAlert);
      default:
        return nothing();
    }
  }
}

export default NotesStore;
