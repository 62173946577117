import { assertNever } from '@kofno/piper';
import { action, makeObservable, observable } from 'mobx';
import { error } from '../../../../../../../ErrorHandling';
import { TPlainTextKey } from '../../../../../../../Translations';
import { EnrollmentResource } from '../../../../../DiscoveryPortal/Enrollment/Store/Types';
import {
  enrollmentError,
  EventState,
  initialize,
  messageReceived,
  paidAndEnrolled,
  PaymentConfirmationEventName,
  paymentError,
  paymentStatusRequested,
  paymentStillProcessing,
  pusherError,
  readyToRedirect,
  requestPaymentStatus,
  waitingForMessage,
} from './Types';

class PaymentWizardStore {
  @observable
  state: EventState;

  constructor() {
    makeObservable(this);

    this.state = initialize();
  }

  @action
  resetPayment = () => {
    this.state = initialize();
  };

  @action
  waitingForMessage = (id: string) => {
    switch (this.state.kind) {
      case 'initializing':
      case 'message-received':
      case 'payment-still-processing':
        this.state = waitingForMessage(id);
        break;
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentStillProcessing = (id: string) => {
    switch (this.state.kind) {
      case 'message-received':
        this.state = paymentStillProcessing(id);
        break;
      case 'initializing':
      case 'payment-still-processing':
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  messageReceived = (
    eventName: PaymentConfirmationEventName,
    enrollmentResponse: unknown,
    paymentIntentId: string,
  ) => {
    switch (this.state.kind) {
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'payment-still-processing':
        this.state = messageReceived(eventName, enrollmentResponse, paymentIntentId);
        break;
      case 'initializing':
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paidAndEnrolled =
    (paymentIntentId: string) =>
    (enrollmentResource: EnrollmentResource): void => {
      switch (this.state.kind) {
        case 'message-received':
          this.state = paidAndEnrolled(enrollmentResource, paymentIntentId);
          break;
        case 'initializing':
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
        case 'payment-still-processing':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
          break;
        default:
          assertNever(this.state);
      }
    };

  @action
  readyToRedirect = (enrollmentResource: EnrollmentResource, paymentIntentId: string): void => {
    switch (this.state.kind) {
      case 'paid-and-enrolled':
        this.state = readyToRedirect(enrollmentResource, paymentIntentId);
        break;
      case 'initializing':
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'message-received':
      case 'payment-still-processing':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestPaymentStatus = (paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'waiting-for-message':
        this.state = requestPaymentStatus(paymentIntentId);
        break;
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'payment-still-processing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentStatusRequested = (paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'request-payment-status':
        this.state = paymentStatusRequested(paymentIntentId);
        break;
      case 'waiting-for-message':
      case 'payment-status-requested':
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'payment-still-processing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  pusherError = (msg: TPlainTextKey, detail: TPlainTextKey, paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'payment-status-requested':
        this.state = pusherError(msg, detail, paymentIntentId);
        break;
      case 'error':
      case 'pusher-error':
      case 'initializing':
      case 'enrollment-error':
      case 'payment-error':
      case 'waiting-for-message':
      case 'message-received':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  enrollmentError = (msg: TPlainTextKey, detail: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'message-received':
        this.state = enrollmentError(msg, detail);
        break;
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'initializing':
      case 'waiting-for-message':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
      case 'payment-status-requested':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentError = (msg: TPlainTextKey, detail: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'message-received':
        this.state = paymentError(msg, detail);
        break;
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'initializing':
      case 'waiting-for-message':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
      case 'payment-status-requested':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default PaymentWizardStore;
