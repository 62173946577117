import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { action, makeObservable, observable } from 'mobx';
import { CurrentUserResource } from '../CurrentUser/Types';
import { LanguageResource, LanguagesResource } from '../ProfileFormStore/Types';

export type State = Ready | Hidden;

export interface Ready {
  kind: 'ready';
}

export interface Hidden {
  kind: 'hidden';
}

const ready = (): Ready => ({
  kind: 'ready',
});

const hidden = (): Hidden => ({
  kind: 'hidden',
});

export const preferredLanguageNotAvailableInExperienceLanguages = (
  languagesResource: LanguagesResource,
  currentUserResource: CurrentUserResource,
): Maybe<LanguageResource> =>
  find(
    (languageResource: LanguageResource) =>
      languageResource.payload.code ===
      currentUserResource.payload.preferredLanguage.getOrElseValue('en'),
    languagesResource.payload,
  );

class NotSupportedInLanguageStore {
  @observable
  state: State;

  constructor(languagesResource: LanguagesResource, currentUserResource: CurrentUserResource) {
    makeObservable(this);

    this.state = preferredLanguageNotAvailableInExperienceLanguages(
      languagesResource,
      currentUserResource,
    )
      .map<State>(hidden)
      .getOrElse(ready);
  }

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'ready':
        break;
      case 'hidden':
        this.state = ready();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  hide = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = hidden();
        break;
      case 'hidden':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default NotSupportedInLanguageStore;
