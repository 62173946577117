import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../SegmentStore';
import { AnyAdvancer } from '../../../../SegmentStore/Types';
import TransitionButton from '../Common/TransitionButton';
import ReadyButton from '../NextAdvanceButton/ReadyButton';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function TokenAdvanceButton({ store, ...others }: Props) {
  const advancer: AnyAdvancer = store.tokenSegmentAdvancer;
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'schedule-session':
      return (
        <ReadyButton
          advancer={advancer}
          confirmationModal={store.confirmationModal}
          store={store}
          {...others}
        />
      );
    case 'advancing':
    case 'advancing-to':
    case 'completing':
    case 'completing-and-advancing':
    case 'reporting-results':
    case 'submitting-and-advancing':
    case 'previous-advancing':
      return <TransitionButton advancer={advancer} {...others} />;
    case 'loaded':
    case 'loading':
    case 'error':
    case 'waiting':
      return emptyFragment();
  }
}

export default observer(TokenAdvanceButton);
