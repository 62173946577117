import { Alert, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';

function FormFlashMessage() {
  return (
    <Alert
      data-testid="success-flash-message"
      className="mb-3 flex-col justify-center sm:mb-7"
      variant="outlined"
      status="success"
    >
      <Typography data-testid="success-flash-message_title" className="mb-3" variant="bodyMedium">
        <T kind="Please check your email for instructions ..." />
      </Typography>
      <Typography data-testid="success-flash-message_description" variant="bodyMedium">
        <T kind="You may now close this window." />
      </Typography>
    </Alert>
  );
}

export default observer(FormFlashMessage);
