import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { creating, Engagement, EngagementState, ready } from './Types';

class EngagementStore {
  @observable
  engagementState: EngagementState = ready();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = () => {
    this.engagementState = ready();
  };

  @action
  create = (engagement: Engagement) => {
    this.engagementState = creating(engagement);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.engagementState = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.engagementState.kind) {
      case 'error':
        return just(this.engagementState).map(errorAlert);
      case 'ready':
      case 'creating':
        return nothing();
    }
  }
}

export default EngagementStore;
