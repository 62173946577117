import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { allMembersLoaded, loadingMore, ProgramMembersState, ready, updateMembers } from './Types';

class ProgramMembersStore {
  perPageLimit = 8;

  @observable
  state: ProgramMembersState = allMembersLoaded(this.perPageLimit);

  constructor() {
    makeObservable(this);
  }

  @action
  loadingMore = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = loadingMore(this.state.currentShownMembers, this.perPageLimit);
        break;
      case 'loading-more':
      case 'all-members-loaded':
      case 'update-members':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (currentShownMembers: number) => {
    switch (this.state.kind) {
      case 'loading-more':
      case 'all-members-loaded':
      case 'ready':
      case 'update-members':
        this.state = ready(currentShownMembers);
        break;
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  allMembersLoaded = (allMembers: number) => {
    switch (this.state.kind) {
      case 'loading-more':
      case 'update-members':
        this.state = allMembersLoaded(allMembers);
        break;
      case 'ready':
      case 'all-members-loaded':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updateMembers = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = updateMembers(this.state.currentShownMembers);
        break;
      case 'loading-more':
        this.state = updateMembers(this.state.loadMoreMembers);
        break;
      case 'all-members-loaded':
        this.state = updateMembers(this.state.allMembers);
        break;
      case 'update-members':
        this.state = updateMembers(this.state.updatedProgramMembers);
        break;
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get shownMembers(): Maybe<number> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.currentShownMembers);
      case 'loading-more':
        return just(this.state.loadMoreMembers);
      case 'all-members-loaded':
        return just(this.state.allMembers);
      case 'update-members':
        return just(this.state.updatedProgramMembers);
      case 'error':
        return nothing();
      default:
        assertNever(this.state);
    }
  }

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'all-members-loaded':
      case 'loading-more':
      case 'update-members':
        return nothing();
    }
  }
}

export default ProgramMembersStore;
