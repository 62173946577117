import { action, makeObservable, observable } from 'mobx';
import { EventPoll, State, pollLaunched, ready } from './Types';

class EventPollStore {
  @observable
  state: State = ready();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  launchPoll = (poll: EventPoll) => {
    this.state = pollLaunched(poll);
  };
}

export default EventPollStore;
