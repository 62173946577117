import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  ChatMessageReaction,
  ChatMessageReactionResource,
  ChatMessageReactionsResource,
} from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { error, Error } from '../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { Link } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';

interface Ready {
  kind: 'ready';
}

interface AddingReaction {
  kind: 'adding-reaction';
  emoji: string;
}

interface RemovingReaction {
  kind: 'removing-reaction';
  reactionResource: ChatMessageReactionResource;
}

const ready = (): Ready => ({
  kind: 'ready',
});

const addingReaction = (emoji: string): AddingReaction => ({
  kind: 'adding-reaction',
  emoji,
});

const removingReaction = (reactionResource: ChatMessageReactionResource): RemovingReaction => ({
  kind: 'removing-reaction',
  reactionResource,
});

export type State = Ready | AddingReaction | RemovingReaction | Error;

class MessageReactionStore {
  baseResourceLinks: ReadonlyArray<Link>;

  currentUserResource: CurrentUserResource;

  @observable
  state: State = ready();

  constructor(baseResourceLinks: ReadonlyArray<Link>, currentUserResource: CurrentUserResource) {
    makeObservable(this);

    this.baseResourceLinks = baseResourceLinks;
    this.currentUserResource = currentUserResource;
  }

  static emojiList = [
    '❤️',
    '❌',
    '✅',
    '💡',
    '🎉',
    '👏',
    '🙁',
    '🤔',
    '😲',
    '🤣',
    '😁',
    '😉',
    '😏',
    '🙂',
    '👎',
    '👍',
  ];

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  updateReaction = (emoji: string, reactionsResource: ChatMessageReactionsResource) => {
    switch (this.state.kind) {
      case 'ready':
      case 'error':
        const reactionExists = (
          reaction: ChatMessageReaction,
          emoji: string,
          currentUserId: number,
        ) => {
          return reaction.emoji === emoji && reaction.userId === currentUserId;
        };
        find(
          (resource: ChatMessageReactionResource) =>
            reactionExists(resource.payload, emoji, this.currentUserResource.payload.id),
          reactionsResource.payload,
        ).cata({
          Just: (reactionResource: ChatMessageReactionResource) =>
            this.removeReaction(reactionResource),
          Nothing: () => this.addReaction(emoji),
        });
        break;
      case 'removing-reaction':
      case 'adding-reaction':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  addReaction = (emoji: string) => {
    switch (this.state.kind) {
      case 'error':
      case 'ready':
        this.state = addingReaction(emoji);
        break;
      case 'removing-reaction':
      case 'adding-reaction':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  removeReaction = (reactionResource: ChatMessageReactionResource) => {
    switch (this.state.kind) {
      case 'error':
      case 'ready':
        this.state = removingReaction(reactionResource);
        break;
      case 'removing-reaction':
      case 'adding-reaction':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'removing-reaction':
      case 'adding-reaction':
        return nothing();
    }
  }
}

export default MessageReactionStore;
