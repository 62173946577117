import { action, makeObservable, observable } from 'mobx';

export type ActiveTab =
  | 'resources'
  | 'outline'
  | 'notes'
  | 'participants'
  | 'group chat'
  | 'agenda';

class TabStore {
  @observable
  activeTab: ActiveTab;

  constructor(activeTab: ActiveTab) {
    makeObservable(this);

    this.activeTab = activeTab;
  }

  @action
  setActiveTab = (activeTab: ActiveTab) => {
    this.activeTab = activeTab;
  };
}

export default TabStore;
