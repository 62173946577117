import { action, makeObservable, observable } from 'mobx';
import clickState from './clickState';

interface Enabled {
  kind: 'enabled';
}

interface Disabled {
  kind: 'disabled';
}

export const assertNever = (x: never) => {
  throw new Error(`Unexpected object: ${x}`);
};

export interface Listener {
  ref: React.RefObject<HTMLElement>;
  callback: () => void;
}

const enabled = (): Enabled => ({ kind: 'enabled' });

const disabled = (): Disabled => ({ kind: 'disabled' });

type State = Enabled | Disabled;

class ClickOutsideStore {
  @observable
  state: State = enabled();
  listeners: Listener[] = [];

  constructor() {
    makeObservable(this);

    document.addEventListener('mousedown', this.onClick);
    document.addEventListener('touchstart', this.onClick);
  }

  @action
  addListener = (listener: Listener) => {
    this.listeners.unshift(listener);
  };

  @action
  enable = () => {
    this.state = enabled();
  };

  @action
  disable = () => {
    this.state = disabled();
  };

  private handleClick = (e: MouseEvent | TouchEvent) => {
    const click = clickState(this.listeners, e);
    switch (click.kind) {
      case 'no-element':
        this.listeners.shift();
        this.handleClick(e);
        break;
      case 'outside-click':
        this.listeners.shift();
        click.listener.callback();
        break;
      case 'no-listener':
      case 'invalid-target':
      case 'inside-click':
        break;
      default:
        assertNever(click);
    }
  };

  private onClick = (e: MouseEvent | TouchEvent) => {
    switch (this.state.kind) {
      case 'enabled':
        this.handleClick(e);
        break;
      case 'disabled':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default new ClickOutsideStore();
