import { action, makeObservable, observable } from 'mobx';
import BreakoutTimeRemainingStore from '../../../components/ConferencePanel/BreakoutTimeRemaining/BreakoutTimeRemainingStore';
import { Breakout } from '../../../TeamsStore/Types';
import CoachRequestStore from '../CoachRequestStore';

class BreakoutChannelStore {
  @observable
  breakout: Breakout;

  coachRequestStore: CoachRequestStore = new CoachRequestStore();
  timeRemainingStore: BreakoutTimeRemainingStore = new BreakoutTimeRemainingStore();

  constructor(breakout: Breakout) {
    makeObservable(this);

    this.breakout = breakout;
  }

  @action
  updateBreakoutTeamId = (id: number) => (this.breakout.teamId = id);
}

export default BreakoutChannelStore;
