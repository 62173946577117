import { action, makeObservable, observable } from 'mobx';
import { StaffRequestState, ready, requested } from './Types';

class StaffRequestStore {
  @observable
  state: StaffRequestState = ready();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  request = () => {
    this.state = requested();
  };
}

export default StaffRequestStore;
