import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { SubscriptionResource } from '../../../../ConversationStore/Types';
import { error, Error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';

interface Ready {
  kind: 'ready';
  subscriptionResource: SubscriptionResource;
}

interface SubscribingToMessage {
  kind: 'subscribing-to-message';
  subscriptionResource: SubscriptionResource;
}

interface UnsubscribingFromMessage {
  kind: 'unsubscribing-from-message';
  subscriptionResource: SubscriptionResource;
}

const ready = (subscriptionResource: SubscriptionResource): Ready => ({
  kind: 'ready',
  subscriptionResource,
});

const subscribingToMessage = (
  subscriptionResource: SubscriptionResource,
): SubscribingToMessage => ({
  kind: 'subscribing-to-message',
  subscriptionResource,
});

const unsubscribingFromMessage = (
  subscriptionResource: SubscriptionResource,
): UnsubscribingFromMessage => ({
  kind: 'unsubscribing-from-message',
  subscriptionResource,
});

export type State = Ready | SubscribingToMessage | UnsubscribingFromMessage | Error;

class SubscriptionStore {
  @observable
  state: State;

  constructor(subscriptionResource: SubscriptionResource) {
    makeObservable(this);

    this.state = ready(subscriptionResource);
  }

  @action
  ready = (subscriptionResource: SubscriptionResource) => {
    this.state = ready(subscriptionResource);
  };

  @action
  subscribingToMessage = (subscriptionResource: SubscriptionResource) => {
    switch (this.state.kind) {
      case 'error':
      case 'ready':
        this.state = subscribingToMessage(subscriptionResource);
        break;
      case 'subscribing-to-message':
      case 'unsubscribing-from-message':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  unsubscribingFromMessage = (subscriptionResource: SubscriptionResource) => {
    switch (this.state.kind) {
      case 'error':
      case 'ready':
        this.state = unsubscribingFromMessage(subscriptionResource);
        break;
      case 'subscribing-to-message':
      case 'unsubscribing-from-message':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'subscribing-to-message':
      case 'unsubscribing-from-message':
        return nothing();
    }
  }

  @computed
  get subscriptionResource(): Maybe<SubscriptionResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'subscribing-to-message':
      case 'unsubscribing-from-message':
        return just(this.state.subscriptionResource);
      case 'error':
        return nothing();
    }
  }
}

export default SubscriptionStore;
