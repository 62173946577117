import { whenPositive } from '@execonline-inc/numbers';
import { just } from 'maybeasy';
import { computed, makeObservable, observable } from 'mobx';
import { ExperienceFilters, ExperienceResource } from '../../../Common/Experience/Types';
import { CompetencyFilterStore } from './CompetencyFilter';
import { DurationFilterStore } from './DurationFilter';
import { LanguageFilterStore } from './LanguageFilter';
import { SchoolPartnerFilterStore } from './SchoolPartnerFilter';
import {
  Filter,
  State,
  allBlank,
  whenAnyMatchingAvailableLanguages,
  whenAnyMatchingCompetency,
  whenAnyMatchingDuration,
  whenAnyMatchingSchoolPartner,
} from './Types';

class FilterStore {
  @observable
  state: State;

  readonly initialFilterValues: Filter;
  readonly languageFilterStore: LanguageFilterStore;
  readonly durationFilterStore: DurationFilterStore;
  readonly schoolPartnerFilterStore: SchoolPartnerFilterStore;
  readonly competencyFilterStore: CompetencyFilterStore;

  constructor(filters: ExperienceFilters) {
    makeObservable(this);

    this.initialFilterValues = {
      availableLanguages: filters.availableLanguages.map((l) => l.payload.code),
      durations: filters.durations.map((d) => d.payload.value),
      schoolPartners: filters.schoolPartners.map((sp) => sp.payload.name),
      competencies: filters.competencies.map((c) => c.payload.name),
    };

    this.state = allBlank(this.initialFilterValues);

    this.languageFilterStore = new LanguageFilterStore(filters);
    this.durationFilterStore = new DurationFilterStore(filters);
    this.schoolPartnerFilterStore = new SchoolPartnerFilterStore(filters);
    this.competencyFilterStore = new CompetencyFilterStore(filters);
  }

  @computed
  get allFilterValues() {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'error':
        return this.state;
    }
  }

  showFilteredExperience = (experience: ExperienceResource): boolean => {
    return (
      whenAnyMatchingAvailableLanguages(experience, this.languageFilterStore) &&
      whenAnyMatchingDuration(experience, this.durationFilterStore) &&
      whenAnyMatchingSchoolPartner(experience, this.schoolPartnerFilterStore) &&
      whenAnyMatchingCompetency(experience, this.competencyFilterStore)
    );
  };

  clearAll = () => {
    this.competencyFilterStore.clearSelectedValues();
    this.durationFilterStore.clearSelectedValues();
    this.schoolPartnerFilterStore.clearSelectedValues();
    this.languageFilterStore.clearSelectedValues();
  };

  apply = () => {
    this.competencyFilterStore.applySelectedValues();
    this.durationFilterStore.applySelectedValues();
    this.schoolPartnerFilterStore.applySelectedValues();
    this.languageFilterStore.applySelectedValues();
  };

  appliedCount = () => {
    return just(
      this.competencyFilterStore.appliedCount +
        this.durationFilterStore.appliedCount +
        this.schoolPartnerFilterStore.appliedCount +
        this.languageFilterStore.appliedCount,
    ).andThen(whenPositive);
  };
}

export default FilterStore;
