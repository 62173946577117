import { Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';
import { ready, State, waiting } from './Types';

class LockedAccessStore {
  @observable
  state: State;

  constructor(message: Maybe<TPlainTextKey>) {
    makeObservable(this);

    this.state = waiting(message);
  }

  @action
  ready = () => {
    this.state = ready(this.state.message);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'waiting':
        return nothing();
      case 'ready':
        return this.state.message.map(error).map(errorAlert);
    }
  }
}

export default LockedAccessStore;
