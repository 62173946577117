import { bifurcateBy, find, uniqBy } from '@execonline-inc/collections';
import { assertNever, identity } from '@kofno/piper';
import { Maybe, isJust, isNothing, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { LocalConferenceRoom } from '../Conference/Types';
import { ChatablePerson, ChatablePersonResourceList } from '../ConversationStore/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { error } from '../ErrorHandling';
import handRaisers from '../HandRaisers';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { Resource } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import {
  ConferenceEventType,
  ConferenceParticipant,
  ConferenceParticipants,
  ConferenceRosterState,
  HandRaisingGroups,
  ProviderParticipant,
  currentUserFirstOrder,
  loading,
  polling,
  pollingComplete,
  ready,
  rosterLoaded,
  stopped,
  updating,
  waiting,
} from './Types';

const chatablePayload = (participant: ConferenceParticipant): ChatablePerson =>
  identity<ChatablePerson>(participant);

const asResource = (chatable: ChatablePerson): Resource<ChatablePerson> => ({
  links: chatable.profile.links,
  payload: chatable,
});

const mapParticipantsToChattables = (
  participants: ConferenceParticipants,
): ChatablePersonResourceList => participants.map(chatablePayload).map(asResource);

const uniqueByName = uniqBy((conferenceParticipant: ConferenceParticipant) =>
  conferenceParticipant.profile.payload.name.getOrElseValue(''),
);

class ConferenceRosterStore {
  @observable
  conferenceRosterState: ConferenceRosterState;

  currentUserResource: CurrentUserResource;
  conferenceRoom: LocalConferenceRoom;

  rosterNameMatch = (
    providerParticipant: ProviderParticipant,
    participant: ConferenceParticipant,
  ): boolean => {
    return (
      providerParticipant.name ===
      participant.profile.payload.name.cata({
        Just: (profileName) => profileName,
        Nothing: () => participant.email,
      })
    );
  };

  constructor(currentUserResource: CurrentUserResource, conferenceRoom: LocalConferenceRoom) {
    makeObservable(this);

    this.conferenceRosterState = waiting();
    this.currentUserResource = currentUserResource;
    this.conferenceRoom = conferenceRoom;
  }

  @action
  loading = () => {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
        this.conferenceRosterState = loading();
        break;
      case 'loading':
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
      case 'error':
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  rosterLoaded = (
    conferenceEventType: ConferenceEventType,
    expectedParticipants: ReadonlyArray<ConferenceParticipant>,
  ) => {
    switch (this.conferenceRosterState.kind) {
      case 'loading':
        this.conferenceRosterState = rosterLoaded(
          conferenceEventType,
          expectedParticipants,
          this.currentUserResource,
        );
        break;
      case 'waiting':
      case 'roster-loaded':
      case 'polling':
      case 'polling-complete':
      case 'ready':
      case 'updating':
      case 'stopped':
      case 'error':
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  ready = () => {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
        break;
      case 'roster-loaded':
      case 'updating':
      case 'polling':
      case 'polling-complete':
        this.conferenceRosterState = ready(this.conferenceRosterState);
        break;
      case 'ready':
      case 'stopped':
      case 'error':
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  updating = (providerParticipants: ReadonlyArray<ProviderParticipant>): void => {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
        break;
      case 'roster-loaded':
      case 'ready':
        this.conferenceRosterState = updating(providerParticipants, this.conferenceRosterState);
        break;
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
      case 'error':
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  polling = (): void => {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
      case 'error':
        break;
      case 'roster-loaded':
      case 'ready':
        this.conferenceRosterState = polling(this.conferenceRosterState);
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  pollingComplete = (providerParticipants: ReadonlyArray<ProviderParticipant>) => {
    switch (this.conferenceRosterState.kind) {
      case 'roster-loaded':
      case 'polling':
        this.conferenceRosterState = pollingComplete(
          providerParticipants,
          this.conferenceRosterState,
        );
        break;
      case 'waiting':
      case 'loading':
      case 'ready':
      case 'updating':
      case 'polling-complete':
      case 'stopped':
      case 'error':
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @action
  stop = (): void => {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
      case 'stopped':
      case 'error':
        break;
      case 'ready':
      case 'roster-loaded':
      case 'updating':
      case 'polling':
      case 'polling-complete':
        this.conferenceRosterState = stopped(this.conferenceRosterState);
        break;
      default:
        assertNever(this.conferenceRosterState);
    }
  };

  @computed
  get eventType(): Maybe<ConferenceEventType> {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
        return just(this.conferenceRosterState.conferenceEventType);
      default:
        return assertNever(this.conferenceRosterState);
    }
  }

  @computed
  get expectedParticipants(): Maybe<ConferenceParticipants> {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
        return just(this.conferenceRosterState.expectedParticipants);
      default:
        return assertNever(this.conferenceRosterState);
    }
  }

  @computed
  get presentParticipants(): Maybe<ConferenceParticipants> {
    const state = this.conferenceRosterState;
    switch (state.kind) {
      case 'waiting':
      case 'loading':
      case 'stopped':
      case 'error':
        return nothing();
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
        return just(
          uniqueByName(
            state.expectedParticipants.filter((participant: ConferenceParticipant) =>
              isJust(
                find(
                  (providerParticipant: ProviderParticipant) =>
                    this.rosterNameMatch(providerParticipant, participant),
                  state.providerParticipants,
                ),
              ),
            ),
          ),
        );
    }
  }

  @computed
  get notPresentParticipants(): Maybe<ConferenceParticipants> {
    const state = this.conferenceRosterState;
    switch (state.kind) {
      case 'waiting':
      case 'loading':
      case 'stopped':
      case 'error':
        return nothing();
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
        return just(
          uniqueByName(
            state.expectedParticipants.filter((participant: ConferenceParticipant) =>
              isNothing(
                find(
                  (providerParticipant: ProviderParticipant) =>
                    this.rosterNameMatch(providerParticipant, participant),
                  state.providerParticipants,
                ),
              ),
            ),
          ),
        );
    }
  }

  @computed
  get handRaisingGroups(): Maybe<HandRaisingGroups> {
    const handRaised = (p: ConferenceParticipant): boolean =>
      handRaisers
        .findHandRaiser(p.id)
        .map((p) => p.isHandRaised)
        .getOrElseValue(false);

    return this.presentParticipants
      .map(bifurcateBy(handRaised))
      .map(([raised, lowered]) => ({ raised, lowered }));
  }

  @computed
  get presentParticipantsSorted(): Maybe<ConferenceParticipants> {
    return this.handRaisingGroups
      .map(({ raised, lowered }) => [...raised, ...lowered])
      .map(currentUserFirstOrder(this.currentUserResource));
  }

  @computed
  get participantsWithHandRaised(): Maybe<ConferenceParticipants> {
    return this.handRaisingGroups.map(({ raised }) => raised);
  }

  @computed
  get participantsWithHandRaisedCount(): number {
    return this.handRaisingGroups.map(({ raised }) => raised.length).getOrElseValue(0);
  }

  @computed
  get participantsWithHandLowered(): Maybe<ConferenceParticipants> {
    return this.handRaisingGroups.map(({ lowered }) => lowered);
  }

  @computed
  get chatablePersonResources(): Maybe<ChatablePersonResourceList> {
    switch (this.conferenceRosterState.kind) {
      case 'waiting':
      case 'loading':
      case 'stopped':
      case 'error':
        return nothing();
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
        return this.expectedParticipants.map(mapParticipantsToChattables);
      default:
        return assertNever(this.conferenceRosterState);
    }
  }

  @action
  error = (msg: TPlainTextKey) => {
    this.conferenceRosterState = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.conferenceRosterState.kind) {
      case 'error':
        return just(this.conferenceRosterState).map(errorAlert);
      case 'loading':
      case 'waiting':
      case 'roster-loaded':
      case 'ready':
      case 'updating':
      case 'polling':
      case 'polling-complete':
      case 'stopped':
        return nothing();
      default:
        return assertNever(this.conferenceRosterState);
    }
  }
}

export default ConferenceRosterStore;
