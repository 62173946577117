import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../../LinkyLinky';
import ProfileFormStore from '../../../ProfileFormStore';
import Label from '../../Form/Label';
import Switcher from './Switcher';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
}

class LanguageSwitcher extends React.Component<Props> {
  render() {
    const { profileStore } = this.props;
    const id: 'language-switcher' = 'language-switcher';
    return just({})
      .assign('updateLink', findLink('update')(profileStore.updateLink))
      .assign('languageSwitcherOptions', profileStore.languageSwitcherOptions)
      .assign('languageSwitcherValue', profileStore.selectedLanguageCode)
      .map(({ updateLink, languageSwitcherOptions, languageSwitcherValue }) => (
        <div className={style.inputSet}>
          <div className={style.labelAndInput}>
            <Label
              className={style.label}
              labelText="Preferred Language"
              htmlFor={id}
              required={true}
            />
            <div className={style.input}>
              <Switcher
                languageSwitcher={languageSwitcherValue}
                updateLink={updateLink}
                languageSwitcherOptions={languageSwitcherOptions}
                profileStore={profileStore}
                id={id}
              />
            </div>
          </div>
        </div>
      ))
      .getOrElse(() => <></>);
  }
}

export default observer(LanguageSwitcher);
