import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import CountryOptionsStore from '../../../CountryOptionsStore';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { T } from '../../../Translations';
import InputWithLabel from '../../Form/InputWithLabel';
import Label from '../../Form/Label';
import CountrySelect from './CountrySelect';
import RegionSelect from './RegionSelect';
import { setValue } from './SetValueHelper';
import TimeZoneSelect from './TimeZoneSelect';
import * as style from './style.module.css';

const timeZoneSelectId: 'primary-time-zone' = 'primary-time-zone';

interface Props {
  profileStore: ProfileFormStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
  countryOptionsStore: CountryOptionsStore;
}

const Work: React.FC<Props> = ({
  profileStore,
  countryRegionOptionsStore,
  countryOptionsStore,
}) => (
  <fieldset className={style.fieldset} data-test-work={true}>
    <h2 className={style.fieldHeader} data-test-work-header="Work">
      <T kind="Work" />
    </h2>
    <div className={style.inputSet}>
      <div className={style.labelAndInput}>
        <InputWithLabel
          labelClassName={style.label}
          className={`${style.input} ${style.readOnly}`}
          labelText="Organization"
          type="text"
          onChange={noop}
          readOnly={true}
          value={profileStore.organization.getOrElseValue('')}
          name="organization"
          id="organization"
        />
      </div>
    </div>
    <div className={style.inputSet}>
      <CountrySelect profileStore={profileStore} countryOptionsStore={countryOptionsStore} />
      <div className={style.labelAndInput}>
        <RegionSelect
          countryRegionOptionsStore={countryRegionOptionsStore}
          profileStore={profileStore}
        />
      </div>
    </div>
    <div className={style.inputSet}>
      <div className={style.labelAndInput}>
        <InputWithLabel
          className={style.input}
          labelText="Division"
          labelClassName={style.label}
          type="text"
          onChange={setValue(profileStore.setDivision)}
          value={profileStore.division.getOrElseValue('')}
          hint="Ex: Technology"
          name="division"
          id="division"
        />
      </div>
      <div className={style.labelAndInput}>
        <InputWithLabel
          className={style.input}
          labelText="Current Position"
          labelClassName={style.label}
          type="text"
          onChange={setValue(profileStore.setCurrentPosition)}
          hint="Ex: Director"
          value={profileStore.position.getOrElseValue('')}
          name="current_position"
          id="current_position"
        />
      </div>
    </div>
    <div className={style.inputSet}>
      <div className={style.labelAndInput}>
        <Label className={style.label} labelText="Primary Time Zone" htmlFor={timeZoneSelectId} />
        <div className={style.input}>
          <TimeZoneSelect profileStore={profileStore} id={timeZoneSelectId} />
        </div>
      </div>
    </div>
  </fieldset>
);

export default observer(Work);
