import { fromNullable } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { now } from 'mobx-utils';
import { Notification } from './Types';

interface _State {
  notification: Notification;
  dismissed: boolean;
}

interface Starting {
  kind: 'starting';
  notification: Notification;
}

interface Dismissed {
  kind: 'dismissed';
  notification: Notification;
}

interface Inactive {
  kind: 'inactive';
  notification: Notification;
}

export const starting = (notification: Notification): Starting => ({
  kind: 'starting',
  notification,
});

export const dismissed = (notification: Notification): Dismissed => ({
  kind: 'dismissed',
  notification,
});

export const inactive = (notification: Notification): Inactive => ({
  kind: 'inactive',
  notification,
});

export type State = Starting | Dismissed | Inactive;

class ReplyNotificationStore {
  @observable
  private _state: _State;

  constructor(notification: Notification) {
    makeObservable(this);

    this._state = {
      notification,
      dismissed: fromNullable(localStorage.getItem('dismissedNotification'))
        .map((dns) => dns.indexOf(this._state.notification.uuid) >= 0)
        .getOrElseValue(false),
    };
  }

  @action
  dismiss = () => {
    this._state.dismissed = true;
  };

  @computed
  get state(): State {
    const time = now(6000);
    if (this._state.dismissed) {
      return dismissed(this._state.notification);
    }
    if (
      this._state.notification.activeAt.getTime() < time &&
      this._state.notification.inactiveAt.getTime() > time
    ) {
      return starting(this._state.notification);
    }

    return inactive(this._state.notification);
  }
}

export default ReplyNotificationStore;
