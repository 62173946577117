import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import ScheduledToastStore from './ScheduledToastStore';
import { loading, ready, reloading, State, ToastsResource, waiting } from './Types';

class ToastsStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = (toastsResource: ToastsResource) => {
    this.state = ready(toastsResource);
  };

  @action
  dismiss = (uuid: string) => {
    this.toasts
      .map((ts) => ts.filter((t) => t.scheduledToast.uuid === uuid))
      .map((ts) => ts.map((t) => t.dismiss()));
  };

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  reloading = (toastsResource: ToastsResource) => {
    this.state = reloading(toastsResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get toasts(): Maybe<NonEmptyList<ScheduledToastStore>> {
    switch (this.state.kind) {
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
      case 'reloading':
      case 'ready':
        return fromArrayMaybe(
          this.state.toastsResource.payload.toasts.map((n) => new ScheduledToastStore(n)),
        );
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'reloading':
      case 'waiting':
        return nothing();
    }
  }
}

export const toastsStore: ToastsStore = new ToastsStore();

export default ToastsStore;
