import { assertNever } from '@kofno/piper';
import { Maybe, just } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  EnrolledModalState,
  closed,
  closedAndRouting,
  confirmAndRouting,
  confirming,
  loading,
  open,
  waiting,
} from './Types';

class EnrolledModalStore {
  @observable
  state: EnrolledModalState = waiting();

  programId: number;

  constructor(programId: number) {
    makeObservable(this);

    this.programId = programId;
  }

  @action
  load = () => {
    this.state = loading();
  };

  @action
  open = () => {
    this.state = open();
  };

  @action
  close = () => {
    switch (this.state.kind) {
      case 'open':
        this.state = confirming();
        break;
      case 'closed-and-routing':
      case 'closed':
      case 'confirming':
      case 'confirm-and-routing':
      case 'loading':
      case 'waiting':
      case 'reopened':
        this.state = closed();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  closeAndRoute = () => {
    switch (this.state.kind) {
      case 'open':
        this.state = confirmAndRouting();
        break;
      case 'closed-and-routing':
      case 'closed':
      case 'confirming':
      case 'loading':
      case 'waiting':
      case 'reopened':
        this.state = closed();
        break;
      case 'confirm-and-routing':
        this.state = closedAndRouting();
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get stringifiedProgramId(): Maybe<string> {
    return just(this.programId.toString());
  }
}

export default EnrolledModalStore;
