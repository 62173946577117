import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { Upload } from '../Uploads';
import {
  loading,
  ready,
  readyWithErrors,
  readyWithoutUpload,
  SimpleFileUploadState,
  uploadInProgess,
  waiting,
} from './Types';

class SimpleFileUploadStore {
  @observable
  state: SimpleFileUploadState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  uploadSuccessful = (newUpload: Upload) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-errors':
      case 'ready-without-upload':
      case 'upload-in-progress': {
        this.state = ready(newUpload);
        break;
      }
      case 'waiting':
      case 'loading':
      case 'upload-successful':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  ready = (newUpload: Upload) => {
    switch (this.state.kind) {
      case 'upload-successful': {
        this.state = ready(newUpload);
        break;
      }
      case 'loading':
      case 'waiting':
      case 'ready':
      case 'ready-with-errors':
      case 'ready-without-upload':
      case 'upload-in-progress':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithoutUpload = () => {
    switch (this.state.kind) {
      case 'loading':
        this.state = readyWithoutUpload();
        break;
      case 'ready':
      case 'upload-successful':
      case 'ready-with-errors':
      case 'waiting':
      case 'upload-in-progress':
      case 'ready-without-upload':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithErrors = (err: string, newUpload: Maybe<Upload>, file: File) => {
    switch (this.state.kind) {
      case 'ready':
      case 'upload-successful':
      case 'ready-without-upload':
      case 'upload-in-progress':
        this.state = readyWithErrors(err, newUpload, file);
        break;
      case 'waiting':
      case 'loading':
      case 'ready-with-errors':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  uploadInProgess = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-without-upload':
      case 'ready-with-errors':
        this.state = uploadInProgess();
        break;
      case 'waiting':
      case 'loading':
      case 'upload-successful':
      case 'upload-in-progress':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'ready-with-errors':
      case 'ready-without-upload':
      case 'upload-in-progress':
      case 'upload-successful':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default SimpleFileUploadStore;
