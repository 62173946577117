import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { Base64ConversionError } from '../Base64';
import { Error, error } from '../ErrorHandling';
import { SaveAsFailed } from '../FileSave';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';

interface Ready {
  kind: 'ready';
  sourceLink: Link;
  fileName: string;
}

interface Downloading {
  kind: 'downloading';
  sourceLink: Link;
  fileName: string;
}

interface Waiting {
  kind: 'waiting';
}

interface Errored {
  kind: 'errored';
  error: SaveAsFailed | Base64ConversionError | Error;
}

const ready = (fileName: string, sourceLink: Link): Ready => {
  return { kind: 'ready', fileName, sourceLink };
};

const downloading = (fileName: string, sourceLink: Link): Downloading => {
  return { kind: 'downloading', fileName, sourceLink };
};

const waiting = (): Waiting => {
  return { kind: 'waiting' };
};

const errored = (error: Errored['error']): Errored => ({ kind: 'errored', error });

export type State = Ready | Waiting | Errored | Downloading;

class PdfStore {
  @observable
  state: State;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  downloading = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = downloading(this.state.fileName, this.state.sourceLink);
        break;
      case 'downloading':
      case 'waiting':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  errored = (e: Errored['error']) => {
    this.state = errored(e);
  };

  @action
  waiting = () => {
    this.state = waiting();
  };

  @action
  ready = (fileName: string, sourceLink: Link) => {
    this.state = ready(fileName, sourceLink);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'errored':
        return just(error('Unable to download PDF')).map(errorAlert);
      case 'ready':
      case 'downloading':
      case 'waiting':
        return nothing();
    }
  }
}

export default PdfStore;
