import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { NonEmptyList } from 'nonempty-list';
import { Link } from '../../../../../../Resource/Types';
import {
  DiscoveryPortalPayload,
  DiscoveryPortalResource,
  ExperienceFiltersResource,
  ExperienceResources,
  NotEnrollableAlertResource,
  ProductCollection,
  UseCase,
} from '../../../../Common/Experience/Types';
import { RegisterResource } from '../../../RegisterResourceStore/Types';
import { error, loading, ready, readyWithSearch, searching, State, waiting } from './Types';

class ExperiencesStore {
  @observable
  state: State = waiting();
  registerResource: RegisterResource;

  constructor(registerResource: RegisterResource) {
    makeObservable(this);

    this.registerResource = registerResource;
  }

  @computed
  get discoveryPortal(): Maybe<DiscoveryPortalPayload> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
        return nothing();
      case 'searching':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
        return just(this.state.resource.payload);
    }
  }

  @computed
  get productCollections(): Maybe<NonEmptyList<ProductCollection>> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
      case 'experiences':
      case 'searching':
        return nothing();
      case 'product-collections':
      case 'product-collections-and-experiences':
        return just(this.state.productCollections);
    }
  }

  @computed
  get experiences(): Maybe<ExperienceResources> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
      case 'experiences':
        return nothing();
      case 'searching':
      case 'product-collections':
      case 'product-collections-and-experiences':
        return just(this.state.resource.payload.experiences);
    }
  }

  @computed
  get useCase(): Maybe<UseCase> {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading':
        return nothing();
      case 'searching':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
        return just(this.state.resource.payload.useCase);
    }
  }

  @computed
  get notEnrollableAlert(): Maybe<NotEnrollableAlertResource> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
        return nothing();
      case 'searching':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
        return this.state.resource.payload.notEnrollableAlert;
    }
  }

  @computed
  get filterOptionsResource(): Maybe<ExperienceFiltersResource> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
        return nothing();
      case 'searching':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
        return just(this.state.resource.payload.experienceFilters);
    }
  }

  @action
  loading = (registerResource: RegisterResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
      case 'error':
      case 'searching':
        this.state = loading(registerResource, this.state);
        break;
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (discoveryPortalResource: DiscoveryPortalResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
        break;
      case 'product-collections':
      case 'loading':
      case 'experiences':
      case 'product-collections-and-experiences':
      case 'searching':
        this.state = ready(discoveryPortalResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithSearch = (
    discoveryPortalResource: DiscoveryPortalResource,
    search: Maybe<string>,
  ): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading':
      case 'product-collections':
      case 'experiences':
      case 'product-collections-and-experiences':
        break;
      case 'searching':
        this.state = readyWithSearch(discoveryPortalResource, search, this.state.allExperiences);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: string): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
      case 'error':
        break;
      case 'searching':
      case 'loading':
        this.state = error(message);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  showExperiences = (): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'experiences':
      case 'product-collections-and-experiences':
      case 'error':
      case 'loading':
      case 'searching':
        break;
      case 'product-collections':
        this.state = {
          kind: 'product-collections-and-experiences',
          productCollections: this.state.productCollections,
          experiences: this.state.resource.payload.experiences,
          search: nothing(),
          allExperiences: this.state.allExperiences,
          resource: this.state.resource,
        };
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  searching = (search: Maybe<string>, link: Link) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'error':
      case 'searching':
        break;
      case 'experiences':
      case 'product-collections-and-experiences':
      case 'product-collections':
        this.state = searching(this.state, search, link, this.state.allExperiences);
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default ExperiencesStore;
