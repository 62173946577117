import { fromBool, toResult } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe, fromEmpty, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { ok } from 'resulty';
import { FlashAlert, errorAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';
import { State, created, creating, error, ready, waiting } from './Types';

class UsePasswordStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = (emailAddress: string, termsAcceptance: boolean, message: Maybe<TPlainTextKey>) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
        this.state = ready(emailAddress, termsAcceptance, message);
        break;
      case 'ready':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setPassword = (password: string) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.password = password;
        break;
      case 'waiting':
      case 'error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTermsAcceptance = (termsAcceptance: boolean) => {
    switch (this.state.kind) {
      case 'ready':
      case 'error':
        this.state.termsAcceptance = termsAcceptance;
        break;
      case 'waiting':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  creating = () => {
    switch (this.state.kind) {
      case 'ready': {
        const { emailAddress, password, termsAcceptance } = this.state;

        ok<TPlainTextKey, string>(password)
          .map(fromEmpty)
          .andThen(toResult("Password can't be blank"))
          .map(fromBool(termsAcceptance))
          .andThen(toResult('Must accept terms of use and privacy policy'))
          .do((password) => (this.state = creating(emailAddress, password)))
          .elseDo(this.error);
        break;
      }
      case 'waiting':
      case 'error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  created = () => {
    switch (this.state.kind) {
      case 'creating':
        this.state = created();
        break;
      case 'waiting':
      case 'ready':
      case 'error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'ready':
      case 'creating':
        this.state = error(message, this.state.emailAddress, this.termsAcceptance);
        break;
      case 'waiting':
      case 'error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get password(): Maybe<string> {
    switch (this.state.kind) {
      case 'creating':
      case 'ready':
        return just(this.state.password);
      case 'waiting':
      case 'error':
      case 'created':
        return nothing();
    }
  }

  @computed
  get termsAcceptance(): boolean {
    switch (this.state.kind) {
      case 'waiting':
        return false;
      case 'ready':
      case 'error':
        return this.state.termsAcceptance;
      case 'creating':
      case 'created':
        return true;
    }
  }

  @computed
  get formDisabled(): boolean {
    switch (this.state.kind) {
      case 'creating':
      case 'waiting':
        return true;
      case 'ready':
      case 'error':
      case 'created':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
        return this.state.message
          .map((message) => ({ kind: 'error', message }) as const)
          .map(errorAlert);
      case 'waiting':
      case 'creating':
      case 'created':
        return nothing();
    }
  }
}

export default UsePasswordStore;
