import { just, Maybe, nothing } from 'maybeasy';
import { computed, makeObservable, observable } from 'mobx';
import { Avatar, avatarFrom } from '../Avatar';
import { CountryOption } from '../CountryOptionsStore/Types';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { Link } from '../Resource/Types';
import {
  ConferenceParticipantPerson,
  LearningPartnerPerson,
  MessageAuthor,
  Person,
  PersonState,
  PersonStoreContract,
  ProfessorPerson,
  TeamMemberPerson,
  UserPerson,
} from './types';

const valueFromPerson =
  (
    teamishFn: (
      teamish:
        | TeamMemberPerson
        | ProfessorPerson
        | MessageAuthor
        | LearningPartnerPerson
        | ConferenceParticipantPerson,
    ) => Maybe<string>,
    userFn: (user: UserPerson) => Maybe<string>,
  ) =>
  (person: Person): Maybe<string> => {
    switch (person.kind) {
      case 'conference-participant':
      case 'learning-partner':
      case 'team-member':
      case 'message-author':
      case 'professor':
        return teamishFn(person);
      case 'user':
        return userFn(person);
    }
  };

const getCountryFromPerson = (person: Person): Maybe<CountryOption> => {
  switch (person.kind) {
    case 'conference-participant':
    case 'learning-partner':
    case 'professor':
    case 'message-author':
    case 'team-member':
      return person.profileResource.profile.payload.country.map((country) => country);
    case 'user':
      return nothing();
  }
};

const getRegionFromPerson = (person: Person): Maybe<CountryOption> => {
  switch (person.kind) {
    case 'conference-participant':
    case 'learning-partner':
    case 'professor':
    case 'message-author':
    case 'team-member':
      return person.profileResource.profile.payload.region.map((region) => region);
    case 'user':
      return nothing();
  }
};

const initials = valueFromPerson(
  (p) => p.profileResource.profile.payload.initials,
  (p) => p.profileResource.payload.initials,
);

const title = valueFromPerson(nothing, (p) => p.profileResource.payload.name);

const shortName = valueFromPerson(nothing, (p) => p.profileResource.payload.shortName);

const name = valueFromPerson((p) => p.profileResource.profile.payload.name, nothing);

const email = valueFromPerson((p) => just(p.profileResource.email), nothing);

const position = valueFromPerson((p) => p.profileResource.profile.payload.position, nothing);

const division = valueFromPerson((p) => p.profileResource.profile.payload.division, nothing);

const cellPhone = valueFromPerson((p) => p.profileResource.profile.payload.cellPhone, nothing);

const workPhone = valueFromPerson(
  (p) => p.profileResource.profile.payload.workPhone.number,
  nothing,
);

const extension = valueFromPerson(
  (p) => p.profileResource.profile.payload.workPhone.extension,
  nothing,
);

const facebookPage = valueFromPerson(
  (p) => p.profileResource.profile.payload.facebookPage,
  nothing,
);

const linkedInPage = valueFromPerson(
  (p) => p.profileResource.profile.payload.linkedInPage,
  nothing,
);

const twitterPage = valueFromPerson((p) => p.profileResource.profile.payload.twitterPage, nothing);

const organization = valueFromPerson(
  (p) => p.profileResource.profile.payload.organization,
  nothing,
);

class PersonStore implements PersonStoreContract {
  @observable
  state: PersonState;

  constructor(personState: PersonState) {
    makeObservable(this);

    this.state = personState;
  }

  @computed
  get personState(): PersonState {
    return this.state;
  }

  @computed
  get kind(): Person['kind'] {
    return this.state.person.kind;
  }

  @computed
  get photo(): Maybe<Link> {
    return this.state.photo;
  }

  @computed
  get initials(): Maybe<string> {
    return initials(this.state.person);
  }

  @computed
  get avatar(): Avatar {
    return avatarFrom(this.photo, this.initials);
  }

  @computed
  get title(): Maybe<string> {
    return title(this.state.person);
  }

  @computed
  get id(): number {
    return this.state.id;
  }

  @computed
  get shortName(): Maybe<string> {
    return shortName(this.state.person);
  }

  @computed
  get name(): Maybe<string> {
    return name(this.state.person);
  }

  @computed
  get email(): Maybe<string> {
    return email(this.state.person);
  }

  @computed
  get position(): Maybe<string> {
    return position(this.state.person);
  }

  @computed
  get division(): Maybe<string> {
    return division(this.state.person);
  }

  @computed
  get cellPhone(): Maybe<string> {
    return cellPhone(this.state.person);
  }

  @computed
  get workPhone(): Maybe<string> {
    return workPhone(this.state.person);
  }

  @computed
  get extension(): Maybe<string> {
    return extension(this.state.person);
  }

  @computed
  get facebookPage(): Maybe<string> {
    return facebookPage(this.state.person);
  }

  @computed
  get linkedInPage(): Maybe<string> {
    return linkedInPage(this.state.person);
  }

  @computed
  get twitterPage(): Maybe<string> {
    return twitterPage(this.state.person);
  }

  @computed
  get organization(): Maybe<string> {
    return organization(this.state.person);
  }

  @computed
  get country(): Maybe<CountryOption> {
    return getCountryFromPerson(this.state.person);
  }

  @computed
  get region(): Maybe<CountryRegionOption> {
    return getRegionFromPerson(this.state.person);
  }
}

export { PersonStore };
