import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import AttachmentUploadStore from '../../../../../AttachmentUploadStore';
import SegmentStore from '../../../../../SegmentStore';
import { SegmentResource } from '../../../../../SegmentStore/Types';
import { AdvanceButton } from '../../Common';
import FormFieldButton from './FormFieldButton';
import ScheduleSessionButton from './ScheduleSessionButton';

interface Props extends ButtonProps {
  store: SegmentStore;
}

const showCallToActionButton = (store: SegmentStore): boolean => {
  return store.segmentResource.cata({
    Just: (segmentResource: SegmentResource) => {
      switch (segmentResource.payload.type) {
        case 'overview':
          return segmentResource.payload.showCompletionCta;
        case 'presentation':
        case 'assignment-due':
        case 'team-discussion':
        case 'external-program':
        case 'lecture':
        case 'survey':
          return false;
      }
    },
    Nothing: () => {
      return false;
    },
  });
};

function SubmitAndAdvanceButton({ store, ...others }: Props) {
  const attachmentUploadStore: AttachmentUploadStore = useConst(() => new AttachmentUploadStore());

  function handleSubmit() {
    store.submitAndAdvance({
      type: 'overview',
      embeddedFormFieldOutputs: store.embeddedFormFieldOutputs,
      attachmentUploadStore: attachmentUploadStore,
    });
  }

  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
      return when(showCallToActionButton(store), true)
        .map(() => <FormFieldButton store={store} {...others} onClick={handleSubmit} />)
        .getOrElse(emptyFragment);

    case 'schedule-session':
      return when(store.isFreebusyCoaching, {})
        .map(() => <ScheduleSessionButton store={store} {...others} onClick={handleSubmit} />)
        .getOrElse(() => <FormFieldButton store={store} {...others} onClick={handleSubmit} />);
    case 'advancing':
    case 'advancing-to':
    case 'completing':
    case 'completing-and-advancing':
    case 'reporting-results':
    case 'submitting-and-advancing':
    case 'previous-advancing':
    case 'loaded':
    case 'loading':
    case 'error':
    case 'waiting':
      return <AdvanceButton {...others} disabled />;
  }
}

export default observer(SubmitAndAdvanceButton);
