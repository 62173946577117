import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { ScrollEvent, ScrollPosition } from './Types';

class ScrollStore {
  private nearValue: number;

  @observable
  scrollEvent: Maybe<ScrollEvent> = nothing();

  constructor(nearValue: number) {
    makeObservable(this);

    this.nearValue = nearValue;
  }

  @action
  scrolling = (scrollEvent: ScrollEvent) => {
    this.scrollEvent = just(scrollEvent);
  };

  @computed
  get scrollPosition(): Maybe<ScrollPosition> {
    return this.scrollEvent
      .map((scrollEvent) => {
        if (scrollEvent.scrollTop >= scrollEvent.scrollHeight - scrollEvent.clientHeight) {
          return just('bottom' as ScrollPosition);
        }
        if (scrollEvent.scrollTop === 0) {
          return just('top' as ScrollPosition);
        }
        if (scrollEvent.scrollTop < this.nearValue) {
          return just('near-top' as ScrollPosition);
        }
        if (
          scrollEvent.scrollTop <
          scrollEvent.scrollHeight - scrollEvent.clientHeight - this.nearValue
        ) {
          return just('near-bottom' as ScrollPosition);
        }
        return just('middle' as ScrollPosition);
      })
      .getOrElseValue(nothing());
  }
}

export default ScrollStore;
