import { action, makeObservable, observable } from 'mobx';

export interface Loading {
  kind: 'loading';
}

export interface Error {
  kind: 'error';
}

export const loading = (): Loading => ({
  kind: 'loading',
});

export const error = (): Error => ({
  kind: 'error',
});

export type ImageState = Loading | Error;

class ImageStore {
  @observable state: ImageState;

  constructor() {
    makeObservable(this);

    this.state = loading();
  }

  @action
  error = () => {
    this.state = error();
  };
}

export default ImageStore;
