import { ButtonProps, ChevronRightIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import { AdvanceButton } from '../../Common';

function CompleteAndAdvanceButton({ children, ...others }: PropsWithChildren<ButtonProps>) {
  const { translate } = useTranslationsContext();

  return (
    <AdvanceButton
      data-testid="complete-and-advance-button"
      title={translate('Complete and Advance')}
      endContent={<ChevronRightIcon color="inherit" size="sm" />}
      {...others}
    >
      {children}
    </AdvanceButton>
  );
}

export default observer(CompleteAndAdvanceButton);
