import { action, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { TPlainTextKey } from '../Translations';
import { loading, ready, ResourceResource, ResourceState, waiting } from './Types';

class ResourceStore {
  @observable
  state: ResourceState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (resourceResource: ResourceResource) => {
    this.state = ready(resourceResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };
}

export default ResourceStore;
