import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert, successAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import {
  CountryRegionOption,
  CountryRegionOptionsResource,
  CountryRegionOptionsResourceWithErrors,
  CountryRegionOptionsState,
  loading,
  readyWithErrors,
  readyWithNoRegions,
  readyWithRegions,
  waiting,
} from './Types';

class CountryRegionOptionsStore {
  @observable
  state: CountryRegionOptionsState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  loading = (countryId: Maybe<string>) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'ready-with-regions':
      case 'ready-without-regions':
      case 'error':
      case 'ready-with-errors':
        this.state = loading(countryId);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithRegions = (
    countryRegionOptionsResource: CountryRegionOptionsResource,
    flashMessage: Maybe<TPlainTextKey>,
    region: Maybe<CountryRegionOption>,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready-with-regions':
        this.state = readyWithRegions(countryRegionOptionsResource, flashMessage, region);
        break;
      case 'ready-without-regions':
      case 'waiting':
      case 'error':
      case 'ready-with-errors':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithNoRegions = (
    countryRegionOptionsResource: CountryRegionOptionsResource,
    flashMessage: Maybe<TPlainTextKey>,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready-without-regions':
        this.state = readyWithNoRegions(countryRegionOptionsResource, flashMessage);
        break;
      case 'ready-with-regions':
      case 'waiting':
      case 'error':
      case 'ready-with-errors':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithErrors = (
    countryRegionOptionsResource: CountryRegionOptionsResourceWithErrors,
    region: Maybe<CountryRegionOption>,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready-with-errors':
        this.state = readyWithErrors(countryRegionOptionsResource, region);
        break;
      case 'ready-with-regions':
      case 'ready-without-regions':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready-with-regions':
      case 'ready-without-regions':
      case 'waiting':
      case 'error':
      case 'ready-with-errors':
        this.state = error(msg);
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready-with-regions':
      case 'ready-without-regions':
        return this.state.flashMessage.andThen(fromEmpty).map(successAlert);
      case 'ready-with-errors':
      case 'waiting':
      case 'loading':
        return nothing();
    }
  }

  @computed
  get countryRegionOptions(): CountryRegionOption[] {
    switch (this.state.kind) {
      case 'ready-with-regions':
      case 'ready-without-regions':
      case 'ready-with-errors':
        return this.state.countryRegionOptionsResource.payload.countryRegionOptions;
      case 'loading':
      case 'waiting':
      case 'error':
        return [];
    }
  }
}

export default CountryRegionOptionsStore;
