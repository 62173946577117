import { action, makeObservable, observable } from 'mobx';
import { initializing, ready, selected, State, waiting } from './Types';

class CoachSelectOptionStore {
  @observable
  state: State = waiting();

  constructor(public id: string) {
    makeObservable(this);
  }

  @action
  initialize = () => {
    this.state = initializing();
  };

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  select = () => {
    this.state = selected();
  };
}
export default CoachSelectOptionStore;
