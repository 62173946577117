import { observer } from 'mobx-react';
import * as React from 'react';
import { SegmentVideoEmbeddable } from '../SegmentEmbed/Types';
import ReqHlsEmbeddedVideoPlayer from './ReqHlsEmbeddedVideoPlayer';

interface Props {
  embeddable: SegmentVideoEmbeddable;
}

const ReqHlsEmbeddedableVideoWrapper: React.FC<Props> = ({ embeddable }) => {
  return (
    <div>
      <ReqHlsEmbeddedVideoPlayer
        uuid={embeddable.node.uuid}
        embeddedVideoAssets={embeddable.assets}
        width="100%"
        height={320}
        dynamicallySetAspectRatio={true}
      />
    </div>
  );
};

export default observer(ReqHlsEmbeddedableVideoWrapper);
