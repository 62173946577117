import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../../../SegmentStore';
import { SelectFormField } from '../../../../Types';
import { fieldHasValue, useFormField, whenMaxLengthValid } from '../../../hooks';
import Legend from '../../../Legend';
import OptionWithStringInput from './OptionWithStringInput';
import * as style from './style.module.css';
interface Props {
  segmentStore: SegmentStore;
  fieldDescription: SelectFormField;
  uuid: string;
}

function CheckboxesSelect({ segmentStore, fieldDescription, uuid }: Props) {
  const { fieldValue, isRequired, onValueChange, addValue, removeValue } = useFormField(
    segmentStore,
    fieldDescription,
    uuid,
  );

  return (
    <fieldset className={style.checkboxFieldset}>
      <Legend label={fieldDescription.label} />

      {fieldDescription.options.map((option) => {
        switch (option.kind) {
          case 'string':
            return (
              <OptionWithStringInput
                key={option.name}
                fieldValue={fieldValue}
                stringFormField={option}
                removeValue={removeValue}
                addValue={addValue}
                whenMaxSelectionValid={() => whenMaxLengthValid(fieldValue, fieldDescription)}
              />
            );
          case 'base-option':
          case 'coach-option':
          case 'leadership-persona-option':
            return (
              <label key={option.value} className={style.checkboxLabel}>
                <input
                  name={option.value}
                  type="checkbox"
                  data-test-checkbox={option.value}
                  onChange={() => onValueChange(option)}
                  checked={fieldHasValue(option, fieldValue)}
                  className={style.checkboxField}
                  required={isRequired}
                />
                {option.label}
              </label>
            );
          default:
            assertNever(option);
        }
      })}
    </fieldset>
  );
}

export default observer(CheckboxesSelect);
