import { readVarR } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import {
  PickerDisplayMode,
  PickerFileMetadata,
  PickerOptions,
  PickerResponse,
} from 'filestack-js/build/main/lib/picker';
import ReactFilestack from 'filestack-react';
import * as React from 'react';
import { ok } from 'resulty';
import { CurrentUserResource } from '../../CurrentUser/Types';
import FileUploadStore from '../../FileUploadStore';
import { languageTag } from '../../LanguageParser';
import { T, TPlainTextKey } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  store: FileUploadStore;
  buttonClass: string;
  buttonText: TPlainTextKey;
  containerClass: string;
  acceptableFileExtensions: string[];
  currentUserResource: CurrentUserResource;
}

/**
 * Wraps Filestack picker. For single-file upload use. Pass in a
 * FileUploadStore and react to it for access uploads and errors.
 */
class AdvancedFileUpload extends React.PureComponent<Props, {}> {
  uploadSuccessful = (res: PickerResponse) => {
    const file = res.filesUploaded[0];
    this.props.store.uploadSuccessful({
      handle: file.handle,
      filename: file.filename,
      uploadFilePath: `/${file.key}`,
      temporaryCdnUrl: file.url,
    });
  };

  uploadFailed = (files: PickerFileMetadata[]) => {
    warn('Upload failed', files);
    this.props.store.error('Your upload could not be processed at this time');
  };

  render() {
    return ok({})
      .assign('region', readVarR('VITE_AWS_REGION'))
      .assign('key', readVarR('VITE_FILESTACK_KEY'))
      .cata({
        Ok: (item) => {
          const options: PickerOptions = {
            storeTo: {
              path: 'uploads/temp-uploads/fs/',
              region: item.region,
              access: 'private',
            },
            displayMode: PickerDisplayMode.overlay,
            dropPane: {},
            container: this.props.containerClass,
            maxFiles: 1,
            fromSources: ['local_file_system'],
            accept: this.props.acceptableFileExtensions,
            uploadInBackground: false,
            lang: this.props.currentUserResource.payload.preferredLanguage
              .andThen(languageTag)
              .getOrElseValue('en'),
          };

          return (
            <div data-test-uploading-available={this.props.buttonText}>
              <ReactFilestack
                apikey={item.key}
                options={options}
                buttonText={<T kind={this.props.buttonText} />}
                buttonClass={this.props.buttonClass}
                onSuccess={this.uploadSuccessful}
                onError={this.uploadFailed}
                cname="upload.execonline.com"
              />
            </div>
          );
        },
        Err: () => {
          return (
            <h2 className={style.unavailable} data-test-uploading-unavailable={true}>
              <T kind="Sorry, photo uploading is currently unavailable" />
            </h2>
          );
        },
      });
  }
}

export default AdvancedFileUpload;
