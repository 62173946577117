import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import SegmentStore from '../../SegmentStore';
import ProgramModuleProgressProviderView from './ProgramModuleProgressProviderView';
import { ProgramModuleProgressContext } from './Types';

interface Props {
  store: SegmentStore;
}

function ProgramModuleProgressProvider({ store, children }: PropsWithChildren<Props>) {
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'completing':
    case 'reporting-results':
    case 'schedule-session':
      return (
        <ProgramModuleProgressProviderView resource={store.state.segmentResource}>
          {children}
        </ProgramModuleProgressProviderView>
      );
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'previous-advancing':
    case 'advancing':
    case 'advancing-to':
    case 'error':
    case 'waiting':
    case 'loading':
    case 'loaded':
      return (
        <ProgramModuleProgressContext.Provider value={nothing()}>
          {children}
        </ProgramModuleProgressContext.Provider>
      );
  }
}

export default observer(ProgramModuleProgressProvider);
