import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import DynamicHeading from '../../../Accessibility/DynamicHeading';
import { ActiveProgram, CompletedProgram } from '../../../ProgramStore/Types';
import { L, T } from '../../../Translations';
import * as style from '../../ProgramSummary/style.module.css';
import { showEndOn } from './Functions';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
  headingLevel?: 3 | 4 | 5 | 6;
  className?: string;
}

function EndDate({ programDetail, headingLevel = 4, className }: Props) {
  return showEndOn(programDetail)
    .map(({ endsOn, daysRemaining }) => (
      <dl className={cn(style.dl, className)}>
        <dt data-test-end-date-label={true}>
          <DynamicHeading level={headingLevel} className={style.subHeader}>
            <T kind="End Date" />
          </DynamicHeading>
        </dt>
        <dd data-test-end-date-value={endsOn}>
          <L localizeable={endsOn} format="long-month-day-year" />
          &nbsp;
          <T kind="({{count}} day remaining)" count={daysRemaining} />
        </dd>
      </dl>
    ))
    .getOrElseValue(<></>);
}
export default observer(EndDate);
