import { log } from '@execonline-inc/logging';
import { action, makeObservable, observable } from 'mobx';
import { Channel } from 'pusher-js';
import { available, ChannelAvailability, notAvailable } from './Types';

class ChannelAvailabilityStore {
  @observable
  channelAvailability: ChannelAvailability = notAvailable();

  constructor() {
    makeObservable(this);
  }

  @action
  notAvailable = () => {
    this.channelAvailability = notAvailable();
  };

  @action
  available = (channel: Channel) => {
    log('[CHANNEL]', `Subscribed to channel ${channel.name}`);
    this.channelAvailability = available(channel);
  };
}

export default ChannelAvailabilityStore;
