import { action, makeObservable, observable } from 'mobx';

interface Inactive {
  kind: 'inactive';
}

interface Active {
  kind: 'active';
}

export const inactive = (): Inactive => ({ kind: 'inactive' });

export const active = (): Active => ({ kind: 'active' });

export type State = Active | Inactive;

class FocusTrapStore {
  @observable
  state: State = inactive();

  constructor() {
    makeObservable(this);
  }

  @action
  deactivate = () => {
    this.state = inactive();
  };

  @action
  activate = () => {
    this.state = active();
  };
}

export default FocusTrapStore;
