import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  AvailabilityQuery,
  AvailabilityRequest,
  EventDetails,
  Freebusy,
  FreebusyKind,
  FreebusyResponse,
  InitSettings,
  Proposal,
} from '../components/Freebusy/Types';
import { Error, error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';

export type State =
  | Waiting
  | InitializingStore
  | Loading
  | Initializing
  | Ready
  | Submitting
  | Submitted
  | Error;

interface Waiting {
  kind: 'waiting';
}

export interface FreebusySettings {
  availabilityRequest: AvailabilityRequest;
  availabilityQuery: AvailabilityQuery;
  initSettings: InitSettings;
}

interface InitializingStore {
  kind: 'initializing-store';
  freebusySettings: FreebusySettings;
  eventUuid: string;
}
interface Initializing {
  kind: 'initializing';
  freebusy: Freebusy;
  freebusySettings: FreebusySettings;
  eventUuid: string;
}

interface Loading {
  kind: 'loading';
  eventUuid: string;
  freebusySettings: FreebusySettings;
}

interface Ready {
  kind: 'ready';
  eventUuid: string;
}

interface Submitting {
  kind: 'submitting';
  eventUuid: string;
  freebusyResponse: FreebusyResponse;
  freebusyProposal: Proposal;
}

interface Submitted {
  kind: 'submitted';
  eventDetails: EventDetails;
}

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const initializingStore = (
  freebusySettings: FreebusySettings,
  eventUuid: string,
): InitializingStore => ({
  kind: 'initializing-store',
  eventUuid,
  freebusySettings,
});

const loading = (freebusySettings: FreebusySettings, eventUuid: string): Loading => ({
  kind: 'loading',
  eventUuid,
  freebusySettings,
});

const submitted = (eventDetails: EventDetails): Submitted => {
  return {
    kind: 'submitted',
    eventDetails,
  };
};

const ready = (state: Initializing): Ready => {
  const { kind, ...previous } = state;
  return {
    kind: 'ready',
    ...previous,
  };
};

const initializing = (
  freebusy: Freebusy,
  freebusySettings: FreebusySettings,
  previousState: Loading,
): Initializing => {
  return {
    ...previousState,
    kind: 'initializing',
    freebusy,
    freebusySettings,
  };
};

const submitting = (
  freebusyResponse: FreebusyResponse,
  freebusyProposal: Proposal,
  state: Ready,
): Submitting => {
  const { kind, ...previous } = state;
  return {
    kind: 'submitting',
    freebusyResponse,
    freebusyProposal,
    ...previous,
  };
};

class FreebusyStore {
  @observable
  state: State;

  constructor(public freebusyKind: FreebusyKind) {
    makeObservable(this);

    this.freebusyKind = freebusyKind;
    this.state = waiting();
  }

  @action
  initializing = (freebusy: Freebusy, freebusySettings: FreebusySettings) => {
    switch (this.state.kind) {
      case 'loading':
        this.state = initializing(freebusy, freebusySettings, this.state);
        break;
      case 'waiting':
      case 'initializing-store':
      case 'initializing':
      case 'ready':
      case 'submitting':
      case 'submitted':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  initializingStore = (freebusySettings: FreebusySettings, eventUuid: string) => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = initializingStore(freebusySettings, eventUuid);
        break;
      case 'initializing-store':
      case 'loading':
      case 'initializing':
      case 'ready':
      case 'submitting':
      case 'submitted':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'initializing-store':
        this.state = loading(this.state.freebusySettings, this.state.eventUuid);
        break;
      case 'waiting':
      case 'loading':
      case 'initializing':
      case 'ready':
      case 'submitting':
      case 'submitted':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  submitting = (freebusyResponse: FreebusyResponse, freebusyProposal: Proposal) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'initializing-store':
      case 'loading':
      case 'initializing':
      case 'submitted':
      case 'submitting':
      case 'error':
        break;
      case 'ready':
        this.state = submitting(freebusyResponse, freebusyProposal, this.state);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  submitted = (eventDetails: EventDetails) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'initializing-store':
      case 'loading':
      case 'initializing':
      case 'ready':
      case 'submitted':
      case 'error':
        break;
      case 'submitting':
        this.state = submitted(eventDetails);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'initializing-store':
      case 'loading':
      case 'ready':
      case 'submitting':
      case 'submitted':
      case 'error':
        break;
      case 'initializing':
        this.state = ready(this.state);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get schedulingDisabled(): boolean {
    switch (this.state.kind) {
      case 'error':
      case 'submitting':
      case 'submitted':
      case 'initializing':
      case 'waiting':
      case 'loading':
      case 'initializing-store':
        return true;
      case 'ready':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'submitting':
      case 'submitted':
      case 'initializing':
      case 'waiting':
      case 'loading':
      case 'initializing-store':
        return nothing();
    }
  }
}

export default FreebusyStore;
