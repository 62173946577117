import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { localPublicUrl } from '../../Native/MobileImageBackground/Types';
import { Footer } from '../Footer';

function WithBackgroundImage({ children }: PropsWithChildren<{}>) {
  return (
    <div
      className="flex min-h-screen w-screen flex-col bg-white bg-cover bg-fixed bg-center"
      style={{
        backgroundImage: `url("${localPublicUrl('login-bg-light.svg')}")`,
      }}
    >
      <div className="mx-auto flex flex-1 flex-col justify-center p-6 text-center xl:container sm:p-12 md:px-0">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default observer(WithBackgroundImage);
