import { ModalBody, ModalFooter, ModalHeader, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Fragment } from 'react';

function SkeletonUpdateFocusModalView() {
  return (
    <>
      <ModalHeader className="flex-col pb-0 sm:pb-0 sm:pt-10">
        <Skeleton className="w-6/12" variant="text" size="xl" />
        <Skeleton className="mt-2 w-8/12" variant="text" size="lg" />
      </ModalHeader>
      <ModalBody className="min-h-115">
        <div className="box-border flex gap-x-3">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} className="w-3/12" variant="text" size="xs" />
          ))}
        </div>
        <div className="mt-10">
          <Skeleton className="w-6/12" variant="text" size="xl" />
          {Array.from({ length: 2 }, (_, index) => (
            <Fragment key={index}>
              <Skeleton className="mt-10 w-full" variant="text" />
              <Skeleton className="mt-4 w-full" variant="text" />
              <Skeleton className="mt-4 w-full" variant="text" />
              <Skeleton className="mt-4 w-8/12" variant="text" />
            </Fragment>
          ))}
          <Skeleton className="mt-10 w-full" variant="text" />
          <Skeleton className="mt-4 w-full" variant="text" />
          <Skeleton className="mt-4 w-8/12" variant="text" />
        </div>
      </ModalBody>
      <ModalFooter className="flex-row justify-between sm:pb-9">
        <Skeleton className="w-4/12" variant="box" size="md" />
        <Skeleton className="w-4/12" variant="box" size="md" />
      </ModalFooter>
    </>
  );
}

export default observer(SkeletonUpdateFocusModalView);
