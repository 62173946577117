import { type Error } from '../../../ErrorHandling';
interface Requested {
  kind: 'requested';
}

interface Ready {
  kind: 'ready';
}

interface Waiting {
  kind: 'waiting';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const requested = (): Requested => ({
  kind: 'requested',
});

export const ready = (): Ready => ({
  kind: 'ready',
});

export type State = Requested | Ready | Waiting | Error;
