import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Linkable } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import { EventsResource, EventsState, loading, ready, refresh, waiting } from './Types';

class EventsStore {
  @observable
  state: EventsState;

  constructor(linkable: Linkable) {
    makeObservable(this);

    this.state = waiting(linkable);
  }

  @action
  loading = (linkable: Linkable) => {
    this.state = loading(linkable);
  };

  @action
  ready = (eventsResource: EventsResource) => {
    this.state = ready(eventsResource);
  };

  @action
  refresh = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = refresh(this.state.eventsResource);
        break;
      case 'error':
      case 'refresh':
      case 'loading':
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'refresh':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get events(): Maybe<EventsResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'refresh':
        return just(this.state.eventsResource);
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get whenLinks(): Maybe<Linkable> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
        return just(this.state.linkable);
      case 'ready':
      case 'error':
      case 'refresh':
        return nothing();
    }
  }
}

export default EventsStore;
