import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import { loading, ready, ResumerRedirectState, waiting } from './States';
import { ResumerResource } from './Types';

class ResumerRedirectStore {
  @observable
  state: ResumerRedirectState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  loading = (link: Link) => {
    this.state = loading(link);
  };

  @action
  ready = (resumerResource: ResumerResource) => {
    this.state = ready(resumerResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get resumerResource(): Maybe<ResumerResource> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
      case 'ready':
        return just(this.state.resumerResource);
    }
  }
}

export default ResumerRedirectStore;
