import { type Error } from '../../../ErrorHandling';
import { Linkable, Resource } from '../../../Resource/Types';
import { FreebusyConfig } from '../../Freebusy/Types';

interface Waiting {
  kind: 'waiting';
}
interface Loading {
  kind: 'loading';
  linkable: Linkable;
}

interface Ready {
  kind: 'ready';
  freebusyProgramAdsResource: FreebusyAdsResource;
}

interface Reloading {
  kind: 'reloading';
  linkable: Linkable;
}

export const ready = (freebusyProgramAdsResource: FreebusyAdsResource): Ready => ({
  kind: 'ready',
  freebusyProgramAdsResource,
});

export const loading = (linkable: Linkable): Loading => ({ kind: 'loading', linkable });

export const reloading = (linkable: Linkable): Reloading => ({ kind: 'reloading', linkable });

export const waiting = (): Waiting => ({ kind: 'waiting' });

export type State = Waiting | Loading | Ready | Reloading | Error;

export type FreebusyAdResource = Resource<FreebusyConfig>;

export type FreebusyAdsResource = Resource<FreebusyAdResource[]>;
