import { equals } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { action, computed, makeObservable, observable } from 'mobx';
import SegmentStore from '../../../../../../../SegmentStore';
import { FieldValue, SelectFormField } from '../../../../../Types';
import { autoSaving, initializing, ready, State, waiting } from './Types';

class DropdownSelectFieldStore {
  @observable
  state: State = waiting();

  constructor(public readonly id: string) {
    makeObservable(this);
  }

  @action
  initialize = (segmentStore: SegmentStore, formField: SelectFormField) => {
    this.state = initializing(segmentStore, formField);
  };

  @action
  ready = (formField: SelectFormField, value: FieldValue[]) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
      case 'initializing':
        this.state = ready(formField, value);
        break;
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setValue = (value: FieldValue[]) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        this.state = ready(this.state.formField, value);
        break;
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  clearValue = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        this.state = ready(this.state.formField, []);
        break;
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSave = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = autoSaving(this.state.formField, this.state.value);
        break;
      case 'auto-saving':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSaveComplete = () => {
    switch (this.state.kind) {
      case 'auto-saving':
        this.state = ready(this.state.formField, this.state.value);
        break;
      case 'ready':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get value(): FieldValue[] {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.value;
      case 'waiting':
      case 'initializing':
        return [];
      default:
        assertNever(this.state);
    }
  }

  @computed
  get isRequired(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.formField.mode === 'required';
      case 'waiting':
      case 'initializing':
        return false;
      default:
        assertNever(this.state);
    }
  }

  @computed
  get isValid(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return equals(this.state.value.length, this.state.formField.maxSelections);
      case 'waiting':
      case 'initializing':
        return false;
      default:
        assertNever(this.state);
    }
  }
}

export default DropdownSelectFieldStore;
