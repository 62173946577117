import { SessionStore as _SessionStore } from '@execonline-inc/unified-auth.private';
import { action, computed, makeObservable, observable } from 'mobx';
import { now } from 'mobx-utils';
import { RootResourceError } from '../RootResourceStore/Types';
import { TPlainTextKey } from '../Translations';

export const sessionStore = makeObservable(
  new _SessionStore<RootResourceError, TPlainTextKey>(now),
  {
    state: observable,
    requesting: action,
    present: action,
    absent: action,
    errored: action,
    userId: computed,
    sessionToken: computed,
    impersonatingBy: computed,
    expiryTimeLeft: computed,
    timeoutState: computed,
  },
);

export type SessionStore = typeof sessionStore;
