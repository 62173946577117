import { log, warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Task } from 'taskarian';
import {
  DOMError,
  appendChild,
  createElement,
  getElementById,
  removeChild,
} from '../../../Document';
import { type Error } from '../../../ErrorHandling';
import { fromNullableT } from '../../../TaskExt';
import * as modalStyle from '../../Modal/style.module.css';
import EventPollStore from './EventPollStore';
import Poll from './Poll';
import * as style from './style.module.css';

interface Props {
  eventPollStore: EventPollStore;
}

class EventPoll extends React.Component<Props> {
  portal?: HTMLElement;

  constructor(props: Props) {
    super(props);
    Task.succeed<DOMError, {}>({})
      .assign('root', getElementById('root'))
      .assign('child', createElement('div'))
      .do(({ child }) => {
        child.id = 'pollsModalPortal';
      })
      .andThen(({ root, child }) => appendChild(root, child))
      .fork(
        ({ message }) => warn(message),
        (portal) => {
          this.portal = portal;
        },
      );
  }

  componentWillUnmount() {
    Task.succeed<Error | DOMError, {}>({})
      .assign('portal', fromNullableT(this.portal))
      .assign('root', getElementById('root'))
      .andThen(({ root, portal }) => removeChild(root, portal))
      .fork(
        ({ message }) => warn(message),
        () => log('Unmount succeed'),
      );
  }

  render() {
    const { eventPollStore } = this.props;

    switch (eventPollStore.state.kind) {
      case 'ready':
        return <></>;
      case 'poll-launched':
        return (
          this.portal &&
          ReactDOM.createPortal(
            <div className={modalStyle.modal}>
              <div className={style.content}>
                <button
                  className={style.closeButton}
                  onClick={eventPollStore.ready}
                  data-test-close-button={true}
                >
                  &times;
                </button>
                <Poll pollUrl={eventPollStore.state.poll.pollUrl} />
              </div>
              <div className={modalStyle.shade} />
            </div>,
            this.portal,
          )
        );
    }
  }
}

export default observer(EventPoll);
