import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error, Error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';

interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
  segmentId: number;
  videoUuid: string;
}

interface Ready {
  kind: 'ready';
}

interface Resizing {
  kind: 'resizing';
}

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const initializing = (segmentId: number, videoUuid: string): Initializing => ({
  kind: 'initializing',
  segmentId,
  videoUuid,
});

const ready = (): Ready => ({
  kind: 'ready',
});

const resizing = (): Resizing => ({
  kind: 'resizing',
});

export type State = Waiting | Initializing | Ready | Resizing | Error;

class LectureSizingStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  initializing = (segmentId: number, videoUuid: string) => {
    this.state = initializing(segmentId, videoUuid);
  };

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  resize = () => {
    this.state = resizing();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'initializing':
      case 'resizing':
      case 'ready':
      case 'waiting':
        return nothing();
    }
  }
}

export default LectureSizingStore;
