import { warnAndNotify } from '@execonline-inc/error-reporting.private';
import { debounce } from '@execonline-inc/rate-limiting.private';
import { milliseconds } from '@execonline-inc/time';
import { noop } from '@kofno/piper';
import { Task } from 'taskarian';
import { postToApi } from '../../Appy';
import { findLinkT } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import SegmentStore from '../../SegmentStore';
import { SegmentReactionErrors } from './Types';

export const autoSavingFormFields = debounce(
  (store: SegmentStore, onError: () => void = noop, onSuccess: () => void = noop) => {
    const fields = store.embeddedFormFieldOutputs.map((embeddedFormFieldOutput) => ({
      id: embeddedFormFieldOutput.id,
      value: embeddedFormFieldOutput.value,
    }));

    Task.succeed<SegmentReactionErrors, ReadonlyArray<Link>>(store.links)
      .andThen(findLinkT('results-create'))
      .andThen(postToApi({ results: fields }))
      .fork(
        (err) => {
          warnAndNotify('SegmentReactions auto-save error', err.kind, err);
          onError();
        },
        () => {
          store.segmentResource.map(store.ready);
          onSuccess();
        },
      );
  },
  milliseconds(200),
);
