import { just, Maybe, nothing } from 'maybeasy';
import { ActiveProgram, CompletedProgram, programEndOnDate } from '../../../ProgramStore/Types';

const whenScheduledProgram = (
  program: ActiveProgram | CompletedProgram,
): Maybe<ActiveProgram | CompletedProgram> => {
  switch (program.programInstanceKind) {
    case 'on-demand':
      return nothing();
    case 'scheduled':
      return just(program);
  }
};

const whenShowEndOn = (
  program: ActiveProgram | CompletedProgram,
): Maybe<ActiveProgram | CompletedProgram> => {
  switch (program.productDetails.payload.kind) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return just(program);
    case 'program':
      return whenScheduledProgram(program);
  }
};

const endingFormatted = (program: ActiveProgram | CompletedProgram) =>
  just({})
    .assign('endsOn', programEndOnDate(program))
    .assign('daysRemaining', program.daysRemaining);

export const showEndOn = (program: ActiveProgram | CompletedProgram) =>
  whenShowEndOn(program).andThen(endingFormatted);
