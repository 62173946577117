import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { TPlainTextKey } from '../../../Translations';
import { creating, Engagement, EngagementState, ready, waiting } from './Types';

class EngagementStore {
  @observable
  engagementState: EngagementState = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  waiting = () => {
    this.engagementState = waiting();
  };

  @action
  ready = () => {
    switch (this.engagementState.kind) {
      case 'waiting':
        this.engagementState = ready();
        break;
      case 'ready':
      case 'error':
      case 'creating':
        break;
      default:
        assertNever(this.engagementState);
    }
  };

  @action
  create = (engagement: Engagement) => {
    switch (this.engagementState.kind) {
      case 'ready':
        this.engagementState = creating(engagement);
        break;
      case 'error':
      case 'waiting':
      case 'creating':
        break;
      default:
        assertNever(this.engagementState);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.engagementState = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.engagementState.kind) {
      case 'error':
        return just(this.engagementState).map(errorAlert);
      case 'ready':
      case 'waiting':
      case 'creating':
        return nothing();
    }
  }
}

export default EngagementStore;
