import { when } from '@execonline-inc/maybe-adapter';
import { fromNullable, just, Maybe } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { ScrollStoreState } from './Types';

class ScrollStore {
  @observable
  state: ScrollStoreState = {
    visibleElementIndex: 0,
    elementsToScroll: 1,
    contentElements: [],
    scrollContainer: null,
    currentScrollContainerPosition: 0,
    maxScrollableDistance: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  addElements(element: HTMLDivElement) {
    this.state.contentElements = [...this.state.contentElements, element];
    this.updateMaxScrollableDistance();
  }

  @action
  addContainer(element: HTMLDivElement) {
    this.state.scrollContainer = element;
    this.updateMaxScrollableDistance();
  }

  @action
  scrollLeft() {
    this.updateMaxScrollableDistance();
    when(this.state.visibleElementIndex > 0, {}).do(() => {
      const contentElement = this.state.contentElements[this.state.visibleElementIndex];
      this.state.currentScrollContainerPosition -= contentElement.offsetWidth;

      this.state.visibleElementIndex -= this.state.elementsToScroll;
      this.scrollToCurrentContentElement();
    });
  }

  @action
  scrollRight() {
    this.updateMaxScrollableDistance();
    when(this.state.visibleElementIndex < this.state.contentElements.length - 1, {}).do(() => {
      this.state.visibleElementIndex += this.state.elementsToScroll;
      this.scrollToCurrentContentElement();

      const contentElement = this.state.contentElements[this.state.visibleElementIndex];
      this.state.currentScrollContainerPosition += contentElement.offsetWidth;
    });
  }

  @action
  scrollToCurrentContentElement() {
    const contentElement: Maybe<HTMLElement> = fromNullable(
      this.state.contentElements[this.state.visibleElementIndex],
    );

    just({})
      .assign('contentElement', contentElement)
      .assign('container', fromNullable(this.state.scrollContainer))
      .do(({ contentElement, container }) => {
        container.scrollTo({
          left: contentElement.offsetLeft,
          behavior: 'smooth',
        });
      });
  }

  @computed
  get contentElementsWidth(): number {
    return this.state.contentElements.reduce((totalWidth, element) => {
      return totalWidth + element.offsetWidth;
    }, 0);
  }

  @action
  getMaxScrollWidth(): number {
    return fromNullable(this.state.scrollContainer)
      .map((container) => this.contentElementsWidth - container.clientWidth)
      .getOrElseValue(0);
  }

  updateMaxScrollableDistance = () => {
    fromNullable(this.state.scrollContainer).do(() => {
      this.state.maxScrollableDistance = this.getMaxScrollWidth();
    });
  };

  @computed
  get rightButtonDisabled() {
    return this.state.currentScrollContainerPosition >= this.state.maxScrollableDistance;
  }

  @computed
  get leftButtonDisabled() {
    return this.state.visibleElementIndex === 0;
  }
}

export default ScrollStore;
