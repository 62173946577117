import { Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../../../ErrorHandling';
import { FlashAlert } from '../../../Notifications/Types';
import { TPlainTextKey } from '../../../Translations';
import { ready, requested, State, waiting } from './Types';

class CoachRequestStore {
  @observable
  state: State;

  constructor() {
    makeObservable(this);

    this.state = ready();
  }

  @action
  requestCoach = () => {
    this.state = requested();
  };

  @action
  waiting = () => {
    this.state = waiting();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    return nothing();
  }
}

export default CoachRequestStore;
