import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  ConjointAnalysisResourceWithErrors,
  CustomerCharacteristicList,
  Loading,
  ProductAttributeList,
  Ready,
  ReadyWithErrors,
  SerializedConjointForm,
  Updating,
  Waiting,
} from '../components/ConjointAnalysis/Types';
import { Error, error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { ServerError } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';

const initialProductAttributes: ProductAttributeList = {
  pa1: nothing(),
  pa1_o1: nothing(),
  pa1_o2: nothing(),
  pa1_desc: nothing(),
  pa2: nothing(),
  pa2_o1: nothing(),
  pa2_o2: nothing(),
  pa2_desc: nothing(),
  pa3: nothing(),
  pa3_o1: nothing(),
  pa3_o2: nothing(),
  pa3_desc: nothing(),
  pa4: nothing(),
  pa4_o1: nothing(),
  pa4_o2: nothing(),
  pa4_desc: nothing(),
};

const initialCustomerCharacteristics: CustomerCharacteristicList = {
  q1: nothing(),
  q1_desc: nothing(),
  q2: nothing(),
  q2_desc: nothing(),
  q3: nothing(),
  q3_desc: nothing(),
  q4: nothing(),
  q4_desc: nothing(),
};

export type ConjointAnalysisState = Waiting | Loading | Updating | Ready | ReadyWithErrors | Error;

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const ready = (
  productAttributes: ProductAttributeList,
  customerCharacteristics: CustomerCharacteristicList,
  description: Maybe<string>,
): Ready => ({
  kind: 'ready',
  description,
  productAttributes,
  customerCharacteristics,
});

const readyWithErrors = (
  productAttributes: ProductAttributeList,
  resource: ConjointAnalysisResourceWithErrors,
  customerCharacteristics: CustomerCharacteristicList,
  description: Maybe<string>,
): ReadyWithErrors => ({
  kind: 'ready-with-errors',
  resource,
  description,
  productAttributes,
  customerCharacteristics,
});

const updating = (
  productAttributes: ProductAttributeList,
  customerCharacteristics: CustomerCharacteristicList,
  description: Maybe<string>,
): Updating => ({
  kind: 'updating',
  description,
  productAttributes,
  customerCharacteristics,
});

const serializedConjointResource = (
  description: Maybe<string>,
  productAttributes: ProductAttributeList,
  customerCharacteristics: CustomerCharacteristicList,
): Maybe<SerializedConjointForm> =>
  just({ p3: true })
    .assign('ps_desc', description)
    .assign('pa1', productAttributes.pa1)
    .assign('pa1_o1', productAttributes.pa1_o1)
    .assign('pa1_o2', productAttributes.pa1_o2)
    .assign('pa1_desc', productAttributes.pa1_desc)
    .assign('pa2', productAttributes.pa2)
    .assign('pa2_o1', productAttributes.pa2_o1)
    .assign('pa2_o2', productAttributes.pa2_o2)
    .assign('pa2_desc', productAttributes.pa2_desc)
    .assign('pa3', productAttributes.pa3)
    .assign('pa3_o1', productAttributes.pa3_o1)
    .assign('pa3_o2', productAttributes.pa3_o2)
    .assign('pa3_desc', productAttributes.pa3_desc)
    .assign('pa4', productAttributes.pa4)
    .assign('pa4_o1', productAttributes.pa4_o1)
    .assign('pa4_o2', productAttributes.pa4_o2)
    .assign('pa4_desc', productAttributes.pa4_desc)
    .assign('q1', customerCharacteristics.q1)
    .assign('q1_desc', customerCharacteristics.q1_desc)
    .assign('q2', customerCharacteristics.q2)
    .assign('q2_desc', customerCharacteristics.q2_desc)
    .assign('q3', customerCharacteristics.q3)
    .assign('q3_desc', customerCharacteristics.q3_desc)
    .assign('q4', customerCharacteristics.q4)
    .assign('q4_desc', customerCharacteristics.q4_desc);

class ConjointAnalysisStore {
  @observable
  state: ConjointAnalysisState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'ready':
      case 'ready-with-errors':
        return nothing();
    }
  }

  @computed
  get validForm(): Maybe<SerializedConjointForm> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
        return nothing();
      case 'updating':
      case 'ready':
      case 'ready-with-errors':
        return serializedConjointResource(
          this.state.description,
          this.state.productAttributes,
          this.state.customerCharacteristics,
        );
    }
  }

  @computed
  get serverErrors(): Maybe<ServerError[]> {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'ready':
        return nothing();
      case 'ready-with-errors':
        return just(this.state.resource.errors);
    }
  }

  @action
  ready = (
    description: Maybe<string> = nothing(),
    productAttributes: ProductAttributeList = initialProductAttributes,
    customerCharacteristics: CustomerCharacteristicList = initialCustomerCharacteristics,
  ) => {
    this.state = ready(productAttributes, customerCharacteristics, description);
  };

  @action
  updating = () => {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
      case 'updating':
        break;
      case 'ready-with-errors':
      case 'ready':
        this.state = updating(
          this.state.productAttributes,
          this.state.customerCharacteristics,
          this.state.description,
        );
        break;
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  readyWithErrors = (resource: ConjointAnalysisResourceWithErrors) => {
    switch (this.state.kind) {
      case 'error':
      case 'waiting':
      case 'loading':
        break;
      case 'updating':
        this.state = readyWithErrors(
          this.state.productAttributes,
          resource,
          this.state.customerCharacteristics,
          this.state.description,
        );
        break;
      case 'ready':
        break;
    }
  };

  @action
  setProductAtributeValue = (name: keyof ProductAttributeList, val: string) => {
    switch (this.state.kind) {
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
        break;
      case 'ready':
      case 'ready-with-errors':
        this.state.productAttributes[name] = fromEmpty(val);
        break;
    }
  };

  @action
  setDescription = (val: string) => {
    switch (this.state.kind) {
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
        break;
      case 'ready':
      case 'ready-with-errors':
        this.state.description = fromEmpty(val);
        break;
    }
  };

  @action
  setCustomerCharacteristicValue = (name: keyof CustomerCharacteristicList, val: string) => {
    switch (this.state.kind) {
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
        break;
      case 'ready':
      case 'ready-with-errors':
        this.state.customerCharacteristics[name] = fromEmpty(val);
        break;
    }
  };
}

export default ConjointAnalysisStore;
