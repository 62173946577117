import { readVarT } from '@execonline-inc/environment';
import { log } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Task } from 'taskarian';
import { loadOneTrust } from './Loader';
import { handleOneTrustLoadError, OneTrustError } from './Types';

function OneTrustTooling() {
  useEffect(() => {
    Task.succeed<OneTrustError, string>('VITE_ONE_TRUST_KEY')
      .andThen(readVarT)
      .andThen(loadOneTrust)
      .fork(handleOneTrustLoadError, () => {
        log('OneTrust Loaded Successfully!', window.OneTrust);
      });
  });

  return <></>;
}

export default observer(OneTrustTooling);
