import { readVarM } from '@execonline-inc/environment';
import { createBrowserHistory } from 'history';

const basename = readVarM('VITE_BASE_NAME').getOrElseValue('');

const historySingleton = () => {
  return createBrowserHistory({ basename });
};

export default historySingleton();
