import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import TeamDiscussionStore from '../../../TeamDiscussionStore';
import { TeamDiscussionSegment } from '../../../TeamDiscussionStore/Types';
import { NotTranslated, T } from '../../../Translations';
import UploadedAttachments from '../AttachmentUploads/UploadedAttachments';
import * as style from './style.module.css';

interface Props {
  segment: TeamDiscussionSegment;
  store: TeamDiscussionStore;
}

@observer
class TeamDiscussionDetails extends React.Component<Props, {}> {
  render() {
    return (
      <div data-test-team-discussion-details={this.props.segment.id}>
        <section className={style.section}>
          <h3 data-test-discussion-title={true}>
            <NotTranslated text={this.props.store.teamDiscussionTitle.getOrElseValue('')} />
          </h3>
          <p className={style.content} data-test-discussion-content={true}>
            <NotTranslated text={this.props.store.teamDiscussionContent.getOrElseValue('')} />
          </p>
        </section>
        {fromArrayMaybe(this.props.segment.uploadedAttachments).cata({
          Just: () => (
            <section className={style.section}>
              <h3 data-test-attachments-title="Attachments">
                <T kind="Attachments" />
              </h3>
              <div className={style.items}>
                <UploadedAttachments segment={this.props.segment} />
              </div>
            </section>
          ),
          Nothing: () => (
            <section className={style.section} data-test-attachments-title="0 Attachments">
              <T kind="0 Attachments" />
            </section>
          ),
        })}
      </div>
    );
  }
}

export default TeamDiscussionDetails;
