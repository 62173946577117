import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';

import { error } from '../../../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../../../Notifications/Types';
import ProgramStore from '../../../../../../ProgramStore';
import { TPlainTextKey } from '../../../../../../Translations';
import { FreebusyAdResource } from '../../../../../Dashboard/FreebusyCoachingAd/Types';
import { loading, ready, reloading, State, waiting } from './Types';

class ScheduleSessionStore {
  @observable
  programStore: ProgramStore;

  @observable
  state: State = waiting();

  constructor(programStore: ProgramStore) {
    makeObservable(this);

    this.programStore = programStore;
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (scheduleSessionResource: FreebusyAdResource) => {
    this.state = ready(scheduleSessionResource);
  };

  @action
  reloading = () => {
    this.state = reloading();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'ready':
        return nothing();
    }
  }
}

export default ScheduleSessionStore;
