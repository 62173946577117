import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import BeginOrResumeButton from '../../BeginOrResumeButton';
import ProgramOverviewHeader from '../../Dashboard/ProgramDashboard/ProgramOverviewHeader';
import * as segStyle from '../../Dashboard/style.module.css';
import TimeRemaining from '../../ProgramOverview/TimeRemaining';
import Modules from './Modules';
import style from './style.module.css';

interface Props {
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
}

const ActiveProgramSequenceOverview: React.FC<Props> = ({ programDetailResource, store }) => (
  <div className={style.journey}>
    <div className={style.header}>
      <div className={`${style.program} mb-0 mr-4`} data-test-active-program={true}>
        <div className={`${segStyle.headerAsBody} ${style.headerBody}`}>
          <ProgramOverviewHeader
            id={programDetailResource.payload.id}
            title={programDetailResource.payload.title}
            links={programDetailResource.links}
          />
        </div>
      </div>
      <BeginOrResumeButton programStore={store} classname={style.btn} />
    </div>
    <div className={style.sections}>
      <div className={style.date}>
        <TimeRemaining
          programDetailResource={programDetailResource}
          headingLevel={4}
          className={style.endTime}
        />
      </div>
      <Modules programDetailResource={programDetailResource} />
    </div>
  </div>
);

export default observer(ActiveProgramSequenceOverview);
