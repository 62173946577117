import { first, flatMap, groupBy, toPairs } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import ChatMessageStore from '../ChatMessageStore';
import EvictingDeque from '../Collections/EvictingDeque';
import { ConversationResource } from '../Conference/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { error } from '../ErrorHandling';
import { findLink } from '../LinkyLinky';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import MessageReplyStore from '../components/TeamProfile/TeamChat/MessageReplyStore';
import {
  ChatMessage,
  ChatMessageReplyResource,
  ChatMessageResource,
  ChatMessagesResource,
  ChatablePersonResourceList,
  ConversationState,
  MessageActionItem,
  MessageGroup,
  chatMessagesResourceWithoutSpecifiedMessage,
  findMessageReplyStoreById,
  firstLoaded,
  getMessageReplyStores,
  loadNewMessage,
  loadNewReply,
  loadNextSuccess,
  loading,
  loadingNext,
  loadingPrevious,
  ready,
  reloading,
  updateMessage,
  updateReply,
  waiting,
} from './Types';

class ConversationStore {
  conversationResource: ConversationResource;
  chatMessageStore: ChatMessageStore;
  chatablePersonResourceList: Maybe<ChatablePersonResourceList>;
  currentUserResource: CurrentUserResource;

  @observable
  messageActionQueue: MessageActionItem[];

  constructor(
    conversationResource: ConversationResource,
    chatablePersonResourceList: Maybe<ChatablePersonResourceList>,
    currentUserResource: CurrentUserResource,
  ) {
    makeObservable(this);

    this.conversationResource = conversationResource;
    this.chatMessageStore = new ChatMessageStore();
    this.state = waiting();
    this.messageActionQueue = [];
    this.chatablePersonResourceList = chatablePersonResourceList;
    this.currentUserResource = currentUserResource;
  }

  @observable
  state: ConversationState;

  @action
  ready = (
    chatMessagesResources: EvictingDeque<ChatMessagesResource>,
    unseenMessage: Maybe<ChatMessageResource>,
  ) => {
    const messageReplyStores = getMessageReplyStores(
      chatMessagesResources,
      this.chatablePersonResourceList,
      this.messageReplyStores,
    );
    this.state = ready(chatMessagesResources, unseenMessage, messageReplyStores);
  };

  @action
  loadingNext = (link: Link) => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = loadingNext(link, this.state.deque, messageReplyStores);
        break;
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'loading-next':
      case 'loading-previous':
      case 'load-next-success':
      case 'load-new-reply':
      case 'load-new-message':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingPrevious = (link: Link) => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = loadingPrevious(link, this.state.deque, messageReplyStores);
        break;
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'loading-next':
      case 'loading-previous':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  reloading = () => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = reloading(this.state.deque, messageReplyStores);
        break;
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'loading-next':
      case 'loading-previous':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  addPreviousPage = (chatMessagesResource: ChatMessagesResource) => {
    switch (this.state.kind) {
      case 'loading-previous':
        const chatMessagesResources = this.state.deque;
        chatMessagesResources.pushBack(chatMessagesResource);
        const messageReplyStores = getMessageReplyStores(
          chatMessagesResources,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = ready(chatMessagesResources, nothing(), messageReplyStores);
        break;
      case 'waiting':
      case 'loading-next':
      case 'loading':
      case 'reloading':
      case 'ready':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  addFirstPage = (messagesResource: ChatMessagesResource) => {
    const deque = new EvictingDeque<ChatMessagesResource>(4);
    deque.pushFront(messagesResource);
    const messageReplyStores = getMessageReplyStores(
      deque,
      this.chatablePersonResourceList,
      this.messageReplyStores,
    );
    this.state = firstLoaded(deque, messageReplyStores);
  };

  @action
  addNextPage = (chatMessagesResource: ChatMessagesResource) => {
    switch (this.state.kind) {
      case 'loading-next':
        const chatMessagesResources = this.state.deque;
        chatMessagesResources.pushFront(chatMessagesResource);
        const messageReplyStores = getMessageReplyStores(
          chatMessagesResources,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = loadNextSuccess(chatMessagesResources, messageReplyStores);
        break;
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'ready':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  newMessage = (message: ChatMessageResource) => {
    switch (this.state.kind) {
      case 'load-new-message':
      case 'load-new-reply':
      case 'ready':
      case 'update-message':
      case 'update-reply':
      case 'loading-next':
      case 'loading-previous':
      case 'load-next-success':
      case 'reloading':
      case 'first-loaded':
        this.messageActionQueue.push({
          createdAt: message.payload.createdAt.getTime(),
          type: 'new-message',
          action: () => {
            this.handleLoadNewMessage(message);
          },
        });
        const chatMessagesResources = this.state.deque;
        const messageReplyStores = getMessageReplyStores(
          chatMessagesResources,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = ready(chatMessagesResources, nothing(), messageReplyStores);
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  handleLoadNewMessage = (message: ChatMessageResource) => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = loadNewMessage(message, this.state.deque, messageReplyStores);
        break;
      case 'load-new-message':
      case 'load-new-reply':
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'load-next-success':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  newReply = (message: ChatMessageReplyResource) => {
    switch (this.state.kind) {
      case 'load-new-message':
      case 'load-new-reply':
      case 'ready':
      case 'update-message':
      case 'update-reply':
      case 'loading-next':
      case 'loading-previous':
      case 'first-loaded':
      case 'load-next-success':
      case 'reloading':
        this.messageActionQueue.push({
          createdAt: message.payload.createdAt.getTime(),
          type: 'new-reply',
          action: () => {
            this.handleLoadNewMessageReply(message);
          },
        });
        const chatMessagesResources = this.state.deque;
        const messageReplyStores = getMessageReplyStores(
          chatMessagesResources,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = ready(chatMessagesResources, nothing(), messageReplyStores);
        break;
      case 'waiting':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  handleLoadNewMessageReply = (reply: ChatMessageReplyResource) => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = loadNewReply(reply, this.state.deque, messageReplyStores);
        break;
      case 'load-new-message':
      case 'load-new-reply':
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'first-loaded':
      case 'load-next-success':
      case 'update-message':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updateMessage = (message: ChatMessageResource) => {
    switch (this.state.kind) {
      case 'ready':
      case 'first-loaded':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = updateMessage(message, this.state.deque, messageReplyStores);
        break;
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'load-next-success':
      case 'load-new-reply':
      case 'load-new-message':
      case 'update-message':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updateReply = (reply: ChatMessageReplyResource) => {
    switch (this.state.kind) {
      case 'ready':
      case 'first-loaded':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = updateReply(reply, this.state.deque, messageReplyStores);
        break;
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'load-next-success':
      case 'load-new-reply':
      case 'load-new-message':
      case 'update-message':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  deleteMessage = (id: number) => {
    switch (this.state.kind) {
      case 'ready':
      case 'first-loaded':
        this.state.deque.items = this.state.deque.items.map(
          chatMessagesResourceWithoutSpecifiedMessage(id),
        );
        this.state.messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        break;
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'update-reply':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  clearUnseenMessage = () => {
    switch (this.state.kind) {
      case 'ready':
        const messageReplyStores = getMessageReplyStores(
          this.state.deque,
          this.chatablePersonResourceList,
          this.messageReplyStores,
        );
        this.state = ready(this.state.deque, nothing(), messageReplyStores);
        break;
      case 'loading-next':
      case 'waiting':
      case 'loading-previous':
      case 'loading':
      case 'reloading':
      case 'load-next-success':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-reply':
      case 'update-message':
      case 'first-loaded':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  removeMessageActionQueueHead = () => {
    this.messageActionQueue.sort();
    this.messageActionQueue.shift();
  };

  @computed
  get firstLoaded(): boolean {
    switch (this.state.kind) {
      case 'reloading':
        return true;
      case 'first-loaded':
        return !!this.state.deque;
      case 'ready':
      case 'load-next-success':
      case 'loading-previous':
      case 'loading-next':
      case 'waiting':
      case 'loading':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'update-reply':
      case 'error':
        return false;
    }
  }

  @computed
  get deque(): Maybe<EvictingDeque<ChatMessagesResource>> {
    switch (this.state.kind) {
      case 'ready':
      case 'loading-next':
      case 'reloading':
      case 'load-next-success':
      case 'loading-previous':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
        return just(this.state.deque);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get chatMessagesResources(): ChatMessagesResource[] {
    return this.deque.map((d) => d.items).getOrElseValue([]);
  }

  @computed
  get messages(): ReadonlyArray<ChatMessage> {
    return this.messageResources.map((message) => message.payload);
  }

  @computed
  get messageResources(): ReadonlyArray<ChatMessageResource> {
    return flatMap((cmr) => cmr.payload, this.chatMessagesResources);
  }

  @computed
  get messageResourceGroups(): MessageGroup[] {
    //TODO: toDateString is temporary until actual date format is implemented
    return toPairs(
      groupBy((m: ChatMessageResource) => m.payload.createdAt.toDateString())(
        this.messageResources,
      ),
    ).map(([dateStamp, messageResources]) => ({ dateStamp, messageResources }));
  }

  @computed
  get nextLink(): Maybe<Link> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.deque.peekFront.map((cmr) => cmr.links).andThen(findLink('next'));
      case 'loading-next':
      case 'reloading':
      case 'load-next-success':
      case 'loading-previous':
      case 'waiting':
      case 'loading':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-reply':
      case 'update-message':
      case 'first-loaded':
      case 'error':
        return nothing();
    }
  }

  @computed
  get unseenMessage(): Maybe<ChatMessageResource> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.unseenMessage;
      case 'loading-next':
      case 'reloading':
      case 'load-next-success':
      case 'loading-previous':
      case 'waiting':
      case 'loading':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        return nothing();
    }
  }

  hasUserMessaged = (unseenMessage: ChatMessageResource): boolean =>
    this.currentUserResource.payload.id === unseenMessage.payload.userId;

  @computed
  get hasCurrentUserMessaged(): boolean {
    switch (this.state.kind) {
      case 'ready':
        return this.unseenMessage
          .map((unseenMessage) => this.hasUserMessaged(unseenMessage))
          .getOrElseValue(false);
      case 'loading-next':
      case 'reloading':
      case 'load-next-success':
      case 'loading-previous':
      case 'waiting':
      case 'loading':
      case 'load-new-reply':
      case 'update-reply':
      case 'load-new-message':
      case 'update-message':
      case 'first-loaded':
      case 'error':
        return false;
    }
  }

  @computed
  get previousLink(): Maybe<Link> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.deque.peekBack.map((cmr) => cmr.links).andThen(findLink('previous'));
      case 'loading-next':
      case 'load-next-success':
      case 'loading-previous':
      case 'reloading':
      case 'waiting':
      case 'loading':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'error':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'reloading':
      case 'loading-next':
      case 'load-next-success':
      case 'loading-previous':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get messageActionQueueHead(): Maybe<MessageActionItem> {
    return first(this.messageActionQueue.concat().sort());
  }

  @computed
  get messageReplyStores(): MessageReplyStore[] {
    switch (this.state.kind) {
      case 'loading-next':
      case 'reloading':
      case 'first-loaded':
      case 'load-next-success':
      case 'loading-previous':
      case 'ready':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'update-reply':
        return this.state.messageReplyStores;
      case 'waiting':
      case 'loading':
      case 'error':
        return [];
    }
  }

  messageReplyStore = createTransformer((id: number): Maybe<MessageReplyStore> => {
    switch (this.state.kind) {
      case 'loading-next':
      case 'reloading':
      case 'first-loaded':
      case 'load-next-success':
      case 'loading-previous':
      case 'ready':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'update-reply':
        return findMessageReplyStoreById(id, this.messageReplyStores);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  });
}

export default ConversationStore;
