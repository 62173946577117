import { action, makeObservable, observable } from 'mobx';

interface ShowingSlides {
  kind: 'showing-slides';
}

interface HidingSlides {
  kind: 'hiding-slides';
}

const showing = (): ShowingSlides => ({
  kind: 'showing-slides',
});

const hiding = (): HidingSlides => ({
  kind: 'hiding-slides',
});

export type State = ShowingSlides | HidingSlides;

class SlideNavigationStore {
  @observable state: State = hiding();

  constructor() {
    makeObservable(this);
  }

  @action
  show = () => {
    this.state = showing();
  };

  @action
  hide = () => {
    this.state = hiding();
  };
}

export default SlideNavigationStore;
