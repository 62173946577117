import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { LoginReactions } from '../../Login/Reactions';
import { LoginStore } from '../../Login/Store';
import LoginView from './LoginView';

function Login() {
  const store = useConst(() => new LoginStore());

  return (
    <>
      <LoginView store={store} />
      <LoginReactions store={store} fireImmediately />
    </>
  );
}

export default observer(Login);
