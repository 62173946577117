import { cn } from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import ThemeContext, { LightnessTheme } from '../../../Themes/ThemeContext';
import {
  TypographyColor,
  TypographyProps,
  TypographyVariant,
  typographyFontWeightClassNameMapping,
  typographyVariantClassNameMapping,
  variantMapping,
} from './Types';

const getComponentName = (
  variant: TypographyVariant,
  component?: React.ElementType,
): React.ElementType => {
  return fromNullable(component).getOrElseValue(variantMapping[variant]);
};

const getTypographyColorValue = (theme: LightnessTheme, color: TypographyColor): string => {
  return theme.colors[`text-${color}-color`];
};

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (
    {
      className,
      color = 'primary',
      fontWeight = 'normal',
      variant = 'body1',
      component,
      children,
      style,
      ...others
    },
    ref,
  ) => {
    const Component = getComponentName(variant, component);

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Component
            className={cn(
              'm-0',
              typographyFontWeightClassNameMapping[fontWeight],
              typographyVariantClassNameMapping[variant],
              className,
            )}
            style={{ color: getTypographyColorValue(theme, color), ...style }}
            {...others}
            ref={ref}
          >
            {children}
          </Component>
        )}
      </ThemeContext.Consumer>
    );
  },
);

export default observer(Typography);
