import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import Notifications from '../../Notifications';
import { Link } from '../../Resource/Types';
import AutoLaunchable from '../AutoLaunchable';
import { Footer } from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import DocumentHandler from './DocumentHandler';
import CourseDocumentReactions from './Reactions';
import CourseDocumentStore from './Store';

interface Params {
  documentId: string;
}

interface Props {
  match: { params: Params };
}

class CourseDocument extends React.Component<Props> {
  store = new CourseDocumentStore();

  componentDidMount() {
    this.store.load(this.documentLink());
  }

  documentId = this.props.match.params.documentId;

  documentLink = (): Link => ({
    href: `${process.env.VITE_API_ROOT}/course_documents/${this.documentId}.json`,
    method: 'get',
    rel: 'self',
    type: 'application/json',
  });

  testing = (href: string) => {
    window.open(href);
  };

  render() {
    return (
      <>
        <div className={platformStyle.container}>
          <SkipNavLink />
          <Toasts teamsStore={nothing()} />
          <NavigationBar />
          <Notifications />
          <AutoLaunchable />
          <Main id="main">
            <DocumentHandler store={this.store} />
          </Main>
          <Footer />
        </div>
        <div className={platformStyle.supPanel} />
        <CourseDocumentReactions store={this.store} />
      </>
    );
  }
}

export default observer(CourseDocument);
