import type { FetchError } from '@execonline-inc/fetch.private';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { Task } from 'taskarian';
import { findLinkT } from '../LinkyLinky';
import { Link, Rel } from '../Resource/Types';
import { error, loading, ready, RootResource, RootResourceError, State } from './Types';

class RootResourceStore {
  @observable
  state: State = loading();

  constructor() {
    makeObservable(this);
  }

  @action
  ready = (resource: RootResource) => {
    this.state = ready(resource);
  };

  @action
  error = (err: FetchError) => {
    this.state = error(err);
  };

  @computed
  get resource(): Maybe<RootResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.resource);
      case 'error':
      case 'loading':
        return nothing();
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    return this.resource.map((resource) => resource.links).getOrElseValue([]);
  }

  @computed
  get fullStoryFeatureSwitch(): boolean {
    switch (this.state.kind) {
      case 'ready':
        return this.state.resource.payload.full_story;
      case 'loading':
      case 'error':
        return false;
    }
  }

  findLinkT = (rel: Rel): Task<RootResourceError, Link> => findLinkT(rel, this.links);
}

export type { RootResourceStore };

export const rootResourceStore = new RootResourceStore();
