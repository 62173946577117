import { fromEmpty, Maybe } from 'maybeasy';
import { makeObservable, observable, toJS } from 'mobx';

class EvictingDeque<T> {
  @observable
  items: T[] = [];

  maxSize: number;

  constructor(maxSize: number) {
    makeObservable(this);

    this.maxSize = maxSize;
  }

  pushFront = (item: T) => {
    this.items = [item, ...toJS(this.items)].slice(0, this.maxSize);
  };

  pushBack = (item: T) => {
    this.items = [...toJS(this.items), item].slice(-this.maxSize);
  };

  popFront = (): Maybe<T> => {
    return fromEmpty(this.items)
      .do(([_, ...rest]) => (this.items = rest))
      .map(([first, ..._]) => first);
  };

  popBack = (): Maybe<T> => {
    return fromEmpty(this.items)
      .do((items) => (this.items = items.slice(0, -1)))
      .map((items) => items.slice(-1)[0]);
  };

  get peekFront(): Maybe<T> {
    return fromEmpty(this.items).map((items) => items[0]);
  }

  get peekBack(): Maybe<T> {
    return fromEmpty(this.items).map((items) => items[items.length - 1]);
  }
}

export default EvictingDeque;
