import { find } from '@execonline-inc/collections';
import { Kettle } from 'kettle-corn';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { LectureSegment, LectureSlide } from '../../../../LectureStore/Types';
import { Resource } from '../../../../Resource/Types';

interface SlidesOnly {
  kind: 'slides-only';
}

interface VideoOnly {
  kind: 'video-only';
}

const slidesOnly = (): SlidesOnly => ({
  kind: 'slides-only',
});

const videoOnly = (): VideoOnly => ({
  kind: 'video-only',
});

export type State = SlidesOnly | VideoOnly;

class SwitcherooStore {
  @observable
  state: State = slidesOnly();

  constructor(
    private segment: LectureSegment,
    private kettle: Kettle,
  ) {
    makeObservable(this);

    reaction(
      () => this.isCurrentlyVideoOnly,
      (isCurrentlyVideoOnly) => {
        if (isCurrentlyVideoOnly) {
          this.videoOnly();
        } else {
          this.slidesOnly();
        }
      },
      { fireImmediately: true },
    );
  }

  @action
  slidesOnly = () => {
    this.state = slidesOnly();
  };

  @action
  videoOnly = () => {
    this.state = videoOnly();
  };

  @computed
  get isCurrentlyVideoOnly(): boolean {
    return this.kettle.videoState.position
      .map((pos) => find((slide: Resource<LectureSlide>) => slide.payload.time <= pos))
      .andThen((fn) => fn([...this.segment.slides].reverse()))
      .map((s) => s.payload.videoonly)
      .getOrElseValue(false);
  }
}

export default SwitcherooStore;
