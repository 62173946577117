import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { broadcasting, BroadcastMessageState, ready, reset, updating, waiting } from './Types';

class BroadcastMessageStore {
  @observable
  state: BroadcastMessageState = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  setMessage = (message: string) => {
    this.state = ready(message);
  };

  @action
  broadcasting = () => {
    switch (this.state.kind) {
      case 'updating':
        this.state = broadcasting(this.state.message);
        break;
      case 'waiting':
      case 'broadcasting':
      case 'error':
      case 'ready':
        break;
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = ready('');
        break;
      case 'broadcasting':
      case 'ready':
      case 'updating':
      case 'error':
        this.state = ready(this.state.message);
        break;
    }
  };

  @action
  reset = () => {
    this.state = reset();
  };

  @action
  updating = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = updating(this.state.message);
        break;
      case 'waiting':
      case 'broadcasting':
      case 'error':
      case 'updating':
        break;
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'broadcasting':
      case 'updating':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get message(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
      case 'broadcasting':
      case 'updating':
        return just(this.state.message);
      case 'waiting':
      case 'error':
        return nothing();
    }
  }
}

export default BroadcastMessageStore;
