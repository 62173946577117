import { observer } from 'mobx-react';
import { error } from '../../ErrorHandling';
import { errorAlert } from '../../Notifications/Types';
import { TPlainTextKey } from '../../Translations';
import { FlashMessage } from '../FlashMessage';
import WithBackgroundImage from '../WithBackgroundImage';
import * as style from './style.module.css';

interface Props {
  errorMessage: TPlainTextKey;
}

function SsoError({ errorMessage }: Props) {
  return (
    <WithBackgroundImage>
      <div className={style.wrapper}>
        <div className={style.main}>
          <FlashMessage alert={errorAlert(error(errorMessage))} />
        </div>
      </div>
    </WithBackgroundImage>
  );
}

export default observer(SsoError);
