import { readVarM } from '@execonline-inc/environment';
import i18next from '@prebsch-exo/i18next';

export const rootLink = () =>
  ({
    rel: 'self',
    href: readVarM('VITE_API_ROOT').getOrElseValue('') + `/roots.json?lng=${i18next.language}`,
    endpoint: '/roots',
    method: 'get',
    type: 'application/json',
  }) as const;
