import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { Upload } from '../Uploads';
import {
  FileUploadState,
  ready,
  removing,
  uploadInProgess,
  uploadSuccessful,
  waiting,
} from './Types';

class FileUploadStore {
  @observable
  state: FileUploadState;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @computed
  get upload(): Maybe<Upload> {
    switch (this.state.kind) {
      case 'upload-successful':
        return just(this.state.upload);
      case 'waiting':
      case 'ready':
      case 'removing':
      case 'upload-in-progress':
      case 'error':
        return nothing();
    }
  }

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = ready();
        break;
      case 'ready':
      case 'removing':
      case 'upload-successful':
      case 'upload-in-progress':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  removing = () => {
    switch (this.state.kind) {
      case 'upload-successful':
      case 'ready':
        this.state = removing();
        break;
      case 'removing':
      case 'upload-in-progress':
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  uploadSuccessful = (upload: Upload) => {
    switch (this.state.kind) {
      case 'ready':
      case 'upload-successful':
      case 'upload-in-progress':
      case 'removing':
      case 'error': {
        this.state = uploadSuccessful(upload);
        break;
      }
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  uploadInProgess = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'removing':
        this.state = uploadInProgess();
        break;
      case 'waiting':
      case 'upload-successful':
      case 'upload-in-progress':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'removing':
      case 'waiting':
      case 'upload-in-progress':
      case 'upload-successful':
        return nothing();
    }
  }
}

export default FileUploadStore;
