import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramDetailResource, whenActive, whenUpcoming } from '../../../ProgramStore/Types';
import EndDate from './EndDate';
import LockedOutDate from './LockedOutDate';
import Upcoming from './Upcoming';

interface Props {
  programDetailResource: ProgramDetailResource;
  headingLevel?: 3 | 4 | 5 | 6;
  className?: string;
}

const TimeRemaining: React.FC<Props> = ({ programDetailResource, headingLevel = 4, className }) => {
  return whenActive(programDetailResource.payload)
    .map((programDetail) => {
      switch (programDetail.accessKind) {
        case 'current-unlocked':
        case 'ended-unlocked':
          return (
            <EndDate
              programDetail={programDetail}
              headingLevel={headingLevel}
              className={className}
            />
          );
        case 'current-warning':
        case 'current-danger':
        case 'ended-warning':
          return <LockedOutDate programDetail={programDetail} kind={'other'} />;
        case 'ended-danger':
          return <LockedOutDate programDetail={programDetail} kind={'danger'} />;
      }
    })
    .orElse(() =>
      whenUpcoming(programDetailResource.payload).map((programDetail) => (
        <Upcoming programDetail={programDetail} />
      )),
    )
    .getOrElseValue(<></>);
};

export default observer(TimeRemaining);
