import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { Errored } from '../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import {
  ContingencyResolution,
  ContingentStore,
} from '../CurrentUser/Context/WhenUserDataLoaded/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { State } from './States';
import { ProgramsResource } from './Types';

class ProgramsStore implements ContingentStore {
  @observable state: State = { kind: 'waiting' };

  constructor() {
    makeObservable(this);
  }

  @action reset = (): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'errored':
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'waiting' };
        break;
      default:
        assertNever(this.state);
    }
  };

  @action loading = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = { kind: 'loading', currentUserResource };
        break;
      case 'ready':
      case 'errored':
      case 'loading':
      case 'refreshing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action refreshing = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state = { kind: 'refreshing', resource: this.state.resource, currentUserResource };
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action ready = (resource: ProgramsResource): void => {
    switch (this.state.kind) {
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'ready', resource };
        break;
      case 'waiting':
      case 'ready':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = (error: Errored['error']): void => {
    switch (this.state.kind) {
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'errored', error };
        break;
      case 'waiting':
      case 'ready':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed get resolution(): ContingencyResolution {
    switch (this.state.kind) {
      case 'ready':
      case 'errored':
        return 'finished';
      case 'waiting':
        return 'waiting';
      case 'loading':
        return 'loading';
      case 'refreshing':
        return 'refreshing';
    }
  }

  @computed get resource(): Maybe<ProgramsResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'refreshing':
        return just(this.state.resource);
      case 'waiting':
      case 'loading':
      case 'errored':
        return nothing();
    }
  }
}

export type { ProgramsStore };

export const programsStore = new ProgramsStore();
