import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import {
  loading,
  ready,
  State,
  TermsAndConditionsResource,
  updated,
  updating,
  waiting,
} from './Types';

class TermsAndConditionsStore {
  @observable
  state: State;

  constructor() {
    makeObservable(this);

    this.state = waiting();
  }

  @action
  ready = (termsResource: TermsAndConditionsResource): void => {
    this.state = ready(termsResource);
  };

  @action
  loading = (): void => {
    this.state = loading();
  };

  @action
  error = (msg: TPlainTextKey): void => {
    this.state = error(msg);
  };

  @action
  save = (): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state = updating(this.state.termsResource);
        break;
      case 'updating':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'updated':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updated = (): void => {
    switch (this.state.kind) {
      case 'updating':
        this.state = updated();
        break;
      case 'ready':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'updated':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'updating':
      case 'ready':
        return this.state.termsResource.links;
      case 'waiting':
      case 'loading':
      case 'error':
      case 'updated':
        return [];
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'updating':
      case 'ready':
      case 'waiting':
      case 'loading':
      case 'updated':
        return nothing();
    }
  }
}

export default TermsAndConditionsStore;
