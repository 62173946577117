import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { fromValue, NonEmptyList } from 'nonempty-list';
import { replyNotificationDecoder } from './Decoders';
import ReplyNotificationStore from './ReplyNotificationStore';
import { ReplyNotification } from './Types';

class ReplyNotificationsStore {
  @observable
  replyNotifications: Maybe<NonEmptyList<ReplyNotification>> = nothing();

  constructor() {
    makeObservable(this);
  }

  @action
  setNotification = (notification: ReplyNotification) => {
    this.replyNotifications = this.replyNotifications
      .map((replyNotifications) => replyNotifications.concat([notification]))
      .orElse(() => just(fromValue(notification)));
  };

  @action
  newNotification = (notification: ReplyNotification) => {
    replyNotificationDecoder.decodeAny(notification).map(this.setNotification);
  };

  @action
  removeKindNotifications = (notification: ReplyNotification) => {
    switch (notification.kind) {
      case 'team-message-reply-notification':
        this.replyNotifications = nothing();
        break;
      case 'program-chat-message-reply-notification':
        this.replyNotifications = nothing();
    }
  };

  @computed
  get getNotifications(): Maybe<NonEmptyList<ReplyNotificationStore>> {
    return this.replyNotifications
      .map((replyNotifications) => fromValue(replyNotifications.first))
      .map((replyNotification) => replyNotification.map((n) => new ReplyNotificationStore(n)));
  }
}

export const replyNotificationsStore: ReplyNotificationsStore = new ReplyNotificationsStore();

export default ReplyNotificationsStore;
